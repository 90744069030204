import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(() => ({
  publicPageListContainer: {
    height: '100%',
    maxHeight: 500,
    width: '100%',
  },
  reportType: {
    fontWeight: 'bold',
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  copyIcon: {
    cursor: 'pointer',
    fontSize: 14,
    marginLeft: 5,
  },
}));
