import { baseApi } from '@sgde/core';
import { Gallery } from '../../models/gallery.model';

const galleryApi = baseApi.enhanceEndpoints({ addTagTypes: ['Gallery'] }).injectEndpoints({
  endpoints: build => ({
    updateGallery: build.mutation<void, Gallery>({
      query: gallery => {
        const formData = new FormData();
        gallery.files.forEach(photo => formData.append(photo.name, photo));
        return { url: `/gallery/${gallery.galleryId}`, method: 'POST', body: formData };
      },
      invalidatesTags: ['Gallery'],
    }),
  }),
});

export const { useUpdateGalleryMutation: useUpdateGallery } = galleryApi;
