import { SetState, StateCreator } from 'zustand';
import { ReportType } from '../../../models/ReportType';
import { AreaMeasurement } from '../../../models/measurement.model';
import { postResource } from '../../Fetch.ts';
import { GIS_CONFIG } from '../../settings/Local.ts';
import { GlobalState } from '../../useStore.ts';

export interface PatrimonyMeasurementsState {
  patrimonyMeasurements: AreaMeasurement[];

  latestPatrimonyMeasurements: AreaMeasurement[];

  getPatrimonyMeasurements: (
    bounds?: number[][],
    customGeometry?: boolean,
    skip?: number,
    publicPageName?: string
  ) => void;

  cleanPatrimonyMeasurements: () => void;
}

const createPatrimonyMeasurementsSlice: StateCreator<PatrimonyMeasurementsState, SetState<GlobalState>> = (
  set,
  get
) => ({
  patrimonyMeasurements: [],
  latestPatrimonyMeasurements: [],
  getPatrimonyMeasurements: async (bounds, customGeometry, skip = 0, publicPageName = '') => {
    const { measurements: latestPatrimonyMeasurements, moreAvailable } = await postResource(
      `${GIS_CONFIG.BASE_API_URL}/constructii/measurements?reportTypes=${ReportType.Patrimoniu}&skip=${skip}&take=1000&customGeometry=${customGeometry}&publicLink=${publicPageName}`,
      bounds
    );

    const patrimonyMeasurements = [...get().patrimonyMeasurements, ...(latestPatrimonyMeasurements || [])];
    set({ patrimonyMeasurements });
    set({ latestPatrimonyMeasurements });
    const newSkip = skip + 1000;
    if (moreAvailable) {
      await get().getPatrimonyMeasurements(bounds, customGeometry, newSkip, publicPageName);
    }
  },
  cleanPatrimonyMeasurements: () => {
    set({ latestPatrimonyMeasurements: [] });
  },
});

export default createPatrimonyMeasurementsSlice;
