import { DeleteIcon, IconButton, List, ListItem, ListItemText } from '@sgde/core';
import { useState } from 'react';
import Dropzone from 'react-dropzone';

type Props = {
  onFilesChanged: (files: File[]) => void;

  dxfFilesToImport: File[];
};
const FileUpload = ({ onFilesChanged, dxfFilesToImport }: Props) => {
  const [dxfFiles, setDxfFiles] = useState<File[]>(dxfFilesToImport);

  const onDrop = (acceptedFiles: File[] | FileList | null) => {
    if (!acceptedFiles) return;
    const acceptedFilesList = [...Array.from(acceptedFiles)];
    const filesToAdd = acceptedFilesList.reduce(
      (acc, curr) => (dxfFiles.map(d => d.name).includes(curr.name) ? acc : [...acc, curr]),
      [] as File[]
    );

    const allFiles = [...dxfFiles, ...filesToAdd];
    setDxfFiles(allFiles);
    onFilesChanged(allFiles);
  };

  const removeFile = (index: number) => {
    const dxfFilesCopy = [...dxfFiles];
    dxfFilesCopy.splice(index, 1);
    setDxfFiles(dxfFilesCopy);
    onFilesChanged(dxfFilesCopy);
  };

  return (
    <>
      <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} accept={{ 'image/x-dxf': ['.dxf'] }}>
        {({ getRootProps, getInputProps }) => (
          <section className="container">
            <div
              style={{
                height: '200px',
                borderColor: '#00000052',
                borderStyle: 'dashed',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: '0 10px',
              }}
              {...getRootProps({ className: 'dropzone' })}
            >
              <input onChange={event => onDrop(event.target.files)} {...getInputProps()} />
              <p style={{ textAlign: 'center' }}>Trageti aici sau dati click pentru a importa fisiere DXF</p>
            </div>
          </section>
        )}
      </Dropzone>
      <List>
        {dxfFiles.map((dxf, index) => (
          <ListItem
            dense
            divider
            key={`${dxf.name}${dxf.lastModified}`}
            secondaryAction={
              <IconButton edge="end" onClick={() => removeFile(index)}>
                <DeleteIcon></DeleteIcon>
              </IconButton>
            }
          >
            <ListItemText primary={dxf.name}></ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default FileUpload;
