import { Box, DialogContent, DialogTitle } from '@sgde/core';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { GalleryItem } from '../../models/gallery-item.model';
import { GIS_CONFIG } from '../../store/settings/Local';

type Props = {
  items: GalleryItem[];

  galleryName: string;
};

const MultiYearGalleryCarousel = ({ items, galleryName }: Props) => {
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);

  return (
    <>
      <DialogTitle>{galleryName}</DialogTitle>
      <DialogContent>
        <>
          {/* library bug that does not compute the initial height corectly, https://github.com/Learus/react-material-ui-carousel/issues/189*/}
          {/* so we need to load the first image to get the correct height */}
          <Box
            alt=""
            component="img"
            src={`${GIS_CONFIG.BASE_API_DOMAIN}/${items[0]?.fileLink}`}
            onLoad={() => setFirstImgLoaded(true)}
            sx={{ display: 'none' }}
          />
          {firstImgLoaded && (
            <Carousel autoPlay={false} navButtonsAlwaysVisible={true}>
              {items.map(item => (
                <Box
                  alt=""
                  component="img"
                  sx={{ width: '100%' }}
                  key={item.id}
                  src={`${GIS_CONFIG.BASE_API_DOMAIN}/${item.fileLink}`}
                />
              ))}
            </Carousel>
          )}
        </>
      </DialogContent>
    </>
  );
};

export default MultiYearGalleryCarousel;
