/**
 * Groups the elements of an array based on a specified key.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} [array=[]] - The array to group.
 * @param {keyof T} key - The key to group by.
 * @returns {Record<string, T[]>} - An object where each key is a group and the value is an array of items in that group.
 *
 * @remarks
 * This function differs from lodash's `groupBy` in that it only accepts a key of the object to group by,
 * whereas lodash allows you to provide a function as the key to handle more complex grouping or nesting.
 */
export function groupBy<T>(array: T[] = [], key: keyof T): Record<string, T[]> {
  return array.reduce(
    (result, item) => {
      const groupKey = String(item[key]);
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    },
    {} as Record<string, T[]>
  );
}
