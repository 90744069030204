import { CONFIG } from '@sgde/core';

const BLOB_STORAGE_URL = window.config?.blobStorageUrl;
if (!BLOB_STORAGE_URL) {
  throw new Error('Missing blobStorageUrl, configure in config.js file');
}

const MENU_TITLE = window.config?.menuTitle;
if (!MENU_TITLE) {
  throw new Error('Missing menuTitle, configure in config.js file');
}

export const GIS_CONFIG = {
  ...CONFIG,
  BLOB_STORAGE_URL,
  MENU_TITLE,
};
