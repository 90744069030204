import { baseApi } from '@sgde/core';
import { Report } from '../../models/report.model';

export interface ISearchReportsRequest {
  searchReportTypeId: number;
  pageSize: number;
  currentPage: number;
  searchTerm: string;
}

interface ISearchReportsResponse {
  items: Report[];
  totalItems: number;
}

const searchApi = baseApi.enhanceEndpoints({ addTagTypes: ['SearchReport'] }).injectEndpoints({
  endpoints: build => ({
    searchReports: build.mutation<ISearchReportsResponse, ISearchReportsRequest>({
      query: data => ({
        url: '/reports/search',
        method: 'POST',
        body: {
          searchReportTypeId: data.searchReportTypeId,
          pageSize: data.pageSize,
          page: data.currentPage,
          searchTerm: data.searchTerm,
        },
      }),
    }),
  }),
});

export const { useSearchReportsMutation: useSearchReports } = searchApi;
