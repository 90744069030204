import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@sgde/core';
import { useContext, useEffect } from 'react';
import { useAllPublicPageSettings } from '../../../store/slices/publicPageApi';
import { ModalContext } from '../ModalContext';
import { useStyles } from './PublicPageList.styles';
import PublicPageListRow from './PublicPageListRow';

const PublicPageList = () => {
  const { dispatch: modal } = useContext(ModalContext);
  const { data: allPublicPageSettings } = useAllPublicPageSettings();
  const { classes } = useStyles();

  useEffect(() => {
    modal?.({
      type: 'actions',
      actions: [],
    });
  }, [modal]);

  return (
    <Box className={classes.publicPageListContainer}>
      {allPublicPageSettings && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, minHeight: 450 }} aria-label="publicpage-table">
            <TableHead>
              <TableRow>
                <TableCell>Titlu Pagină</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Zoom</TableCell>
                <TableCell>Măsurători Afișate</TableCell>
                <TableCell>Furnizor Harta</TableCell>
                <TableCell>Streetview</TableCell>
                <TableCell>Ortofotoplan</TableCell>
                <TableCell>Tip fundal</TableCell>
                <TableCell>Câmpuri Vizibile</TableCell>
                <TableCell>Ștergere</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPublicPageSettings.map(row => (
                <PublicPageListRow key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default PublicPageList;
