import { Button, DialogActions, DialogTitle, useSnackbar } from '@sgde/core';
import { ReportType } from '../../models/ReportType';
import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { postResource } from '../../store/Fetch';
import { GIS_CONFIG } from '../../store/settings/Local';

type Props = {
  feature: SGDEFeature;
  closeDialog: () => void;
  map: OpenLayersMap;
  showReportDialog?: (features: SGDEFeature[]) => void;
};

const CreateMeasurement = ({ feature, closeDialog, map }: Props) => {
  const { openSnackbar } = useSnackbar();
  const onSave = async () => {
    try {
      await postResource(`${GIS_CONFIG.BASE_API_URL}/measurements/`, feature.getAreaMeasurement());
      map.reloadMeasurementsLayer(ReportType.Masuratoare);
      closeDialog();
      openSnackbar({ severity: 'success', message: 'Masuratoare creata cu succes' });
    } catch (ex) {
      openSnackbar({ severity: 'error', message: 'Eroare la crearea masuratorii' });
    }
  };

  return (
    <>
      <DialogTitle>Creaza masuratoare </DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={onSave}>
          Salveaza
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateMeasurement;
