import { baseApi } from '@sgde/core';
import { GpsCoordinate } from '../../models/coordinate.model';
import { DayForecast } from '../../models/day-forecast.model';

const weatherApi = baseApi.enhanceEndpoints({ addTagTypes: ['Weather'] }).injectEndpoints({
  endpoints: build => ({
    get5DayForecast: build.mutation<DayForecast[], GpsCoordinate>({
      query: location => {
        return { url: `/weather/5day`, method: 'POST', body: location };
      },
      invalidatesTags: ['Weather'],
    }),
  }),
});

export const { useGet5DayForecastMutation: useGet5DayForecast } = weatherApi;
