import { SetState, StateCreator } from 'zustand';
import { AreaMeasurement } from '../../../models/measurement.model';
import { postResource } from '../../Fetch.ts';
import { GIS_CONFIG } from '../../settings/Local.ts';
import { GlobalState } from '../../useStore.ts';

export interface EmptyMeasurementsState {
  emptyMeasurements: AreaMeasurement[];

  latestEmptyMeasurements: AreaMeasurement[];

  getEmptyMeasurements: (bounds?: number[][], customGeometry?: boolean, skip?: number, publicPageName?: string) => void;

  cleanEmptyMeasurements: () => void;

  abortController?: AbortController;
}

const createEmptyMeasurementsSlice: StateCreator<EmptyMeasurementsState, SetState<GlobalState>> = (set, get) => ({
  emptyMeasurements: [],
  latestEmptyMeasurements: [],
  getEmptyMeasurements: async (bounds, customGeometry, skip = 0, publicPageName = '') => {
    const abortController = new AbortController();
    set({ abortController });
    const { measurements: latestEmptyMeasurements, moreAvailable } = await postResource(
      `${GIS_CONFIG.BASE_API_URL}/constructii/measurements/empty?skip=${skip}&take=1000&customGeometry=${customGeometry}&publicLink=${publicPageName}`,
      bounds,
      abortController.signal
    );

    const emptyMeasurements = [...get().emptyMeasurements, ...(latestEmptyMeasurements || [])];
    set({ emptyMeasurements });
    set({ latestEmptyMeasurements });
    const newSkip = skip + 1000;
    if (moreAvailable) {
      await get().getEmptyMeasurements(bounds, customGeometry, newSkip, publicPageName);
    }
  },
  cleanEmptyMeasurements: () => {
    get().abortController?.abort();
    set({ latestEmptyMeasurements: [] });
  },
});

export default createEmptyMeasurementsSlice;
