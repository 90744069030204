import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useSnackbar,
} from '@sgde/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import DxfFileContents from '../../models/DxfFileContents';
import { ProgressContext } from '../../primitives/Progress/Progress';
import { useImportDxfFiles } from '../../store/slices/dxfApi';
import FileUpload from './FileUpload';
import FinalizeImport from './FinalizeImport';
import ImportOptions from './ImportOptions';

type Props = {
  closeDialog?: () => void;
};

const steps = ['Incarcare fisiere', 'Optiuni import', 'Rulare import'];

const DxfImport = ({ closeDialog }: Props) => {
  const [dxfFiles, setDxfFiles] = useState<File[]>([]);
  const [fieldsToLink, setFieldsToLink] = useState<number[]>([]);
  const [reportType, setReportType] = useState<number>(1);
  const [step, setStep] = useState(0);
  const [dxfFileContents, setDxfFileContents] = useState<DxfFileContents[]>([]);
  const [importDxfFiles, { isError, isSuccess, isLoading }] = useImportDxfFiles();
  const { openSnackbar } = useSnackbar();
  const { dispatch: progress } = useContext(ProgressContext);

  const onImportOptionsChange = (reportType: number, fieldsToLink: number[]) => {
    setReportType(reportType);
    setFieldsToLink(fieldsToLink);
  };

  const getComponentForStep = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return <FileUpload dxfFilesToImport={dxfFiles} onFilesChanged={dxfFiles => setDxfFiles(dxfFiles)} />;
        case 1:
          return (
            <ImportOptions
              selectedFieldsToLink={fieldsToLink}
              selectedReportType={reportType}
              dxfFiles={dxfFiles}
              onOptionsChange={(reportType, fieldsToLink) => onImportOptionsChange(reportType, fieldsToLink)}
              onDxfFileContentsChange={dxfFileContents => setDxfFileContents(dxfFileContents)}
            />
          );
        case 2:
          return (
            <FinalizeImport
              reportType={reportType}
              fieldsToLink={fieldsToLink}
              dxfFileContents={dxfFileContents}
            ></FinalizeImport>
          );
        default:
          return <></>;
      }
    },
    [dxfFiles, step]
  );

  const runImport = () => {
    importDxfFiles({
      files: dxfFiles.map(f => f.name),
      fieldsToLink,
      reportType,
    });
  };

  useEffect(() => {
    if (isError) {
      openSnackbar({ severity: 'error', message: 'Eroare la importarea fisierelor dxf' });
    }

    if (isSuccess) {
      openSnackbar({ severity: 'success', message: 'Importarea fisierelor dxf a fost realizata cu success!' });
      closeDialog?.();
    }

    progress?.(isLoading ? 'show' : 'hide');
  }, [isError, isSuccess, isLoading]);

  return (
    <div style={{ width: '100%' }}>
      <DialogTitle>Import DXF</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={step}>
              {steps.map(label => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {getComponentForStep(step)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: step > 0 ? 'space-between' : 'end' }}>
        {step > 0 && (
          <Button color="primary" onClick={() => setStep(step - 1)}>
            Inapoi
          </Button>
        )}
        {step < steps.length - 1 ? (
          <Button color="primary" onClick={() => setStep(step + 1)}>
            Inainte
          </Button>
        ) : (
          <Button color="primary" disabled={isLoading} onClick={() => runImport()}>
            Finalizare import
          </Button>
        )}
      </DialogActions>
    </div>
  );
};

export default DxfImport;
