import { baseApi } from '@sgde/core';

const downloadBlob = (blob: Blob, fileName: string) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const fileLink = document.createElement('a');
  fileLink.href = blobUrl;
  const date = new Date();
  fileLink.download = `${fileName}-${window.location.hostname}-${date.toLocaleDateString()}.csv`;
  fileLink.click();
};

const generateReportsApi = baseApi.enhanceEndpoints({ addTagTypes: ['GeneratedReports'] }).injectEndpoints({
  endpoints: build => ({
    generateReport1: build.mutation<any, any>({
      query: data => ({
        url: 'generatereports',
        method: 'POST',
        body: data,
      }),
    }),
    generateReport2: build.mutation<any, any>({
      query: data => ({
        url: 'generatereports2',
        method: 'POST',
        body: data,
      }),
    }),
    downloadReport: build.mutation<any, any>({
      query: data => ({
        url: 'downloadreports',
        method: 'POST',
        body: data,
      }),
    }),
    downloadReport1Blob: build.mutation<File, void>({
      query: () => ({
        url: 'exportreport1tocsv',
        method: 'GET',
        responseHandler: async response => {
          const blob = await response.blob();
          downloadBlob(blob, 'RaportImobile');
        },
        cache: 'no-cache',
      }),
    }),
    downloadReport2Blob: build.mutation<File, void>({
      query: () => ({
        url: 'exportreport2tocsv',
        method: 'GET',
        responseHandler: async response => {
          const blob = await response.blob();
          downloadBlob(blob, 'RaportPatrimoniu');
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGenerateReport1Mutation: useGenerateReport1,
  useGenerateReport2Mutation: useGenerateReport2,
  useDownloadReportMutation: useDownloadReport,
  useDownloadReport1BlobMutation: useDownloadReport1Blob,
  useDownloadReport2BlobMutation: useDownloadReport2Blob,
} = generateReportsApi;
