import { CloseIcon, IconButton, Dialog as MuiDialog } from '@sgde/core';
import { MouseEventHandler, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  onClose?: MouseEventHandler;
  open: boolean;
  children: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export default ({ children, onClose, maxWidth, open }: Props) =>
  createPortal(
    <MuiDialog maxWidth={maxWidth} fullWidth open={open}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.error.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </MuiDialog>,
    document.getElementById('dialog-root')!
  );
