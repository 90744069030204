import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(() => ({
  ol_popup: {
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '0 1px 4px rgba(0,0,0,0.2)',
    padding: '8px',
    borderRadius: '10px',
    border: '1px solid #cccccc',
    bottom: '12px',
    left: '-50px',
    minWidth: '50px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    color: 'black',
    width: 'max-content',
    '&:after, :before': {
      top: '100%',
      border: 'solid transparent',
      content: '" "',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
    },
    '&:after': {
      borderTopColor: 'white',
      borderWidth: '10px',
      left: '48px',
      marginLeft: '-10px',
    },
    '&:before': {
      borderTopColor: '#cccccc',
      borderWidth: '11px',
      left: '48px',
      marginLeft: '-11px',
    },
  },
}));
