import { Button, Grid, SaveIcon, useSnackbar } from '@sgde/core';
import { useContext, useEffect, useState } from 'react';
import { ReportType } from '../../models/ReportType';
import { DocumentLibrary } from '../../models/document-library.model';
import SGDEFeature from '../../models/feature/sgde-feature';
import { Gallery } from '../../models/gallery.model';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { AreaMeasurement, CreateAreaMeasurement } from '../../models/measurement.model';
import { ReportField, ReportFieldOptional } from '../../models/report-field.model';
import { Report } from '../../models/report.model';
import { ModalContext } from '../../primitives/Modal/ModalContext';
import { postResource } from '../../store/Fetch';
import { GIS_CONFIG } from '../../store/settings/Local';
import SearchReport from '../SearchReport/SearchReport';

type Props = {
  map?: OpenLayersMap;

  report: Report;

  additionalReportFields: ReportField[];

  measurement: CreateAreaMeasurement;

  existingMeasurement?: AreaMeasurement;

  feature: SGDEFeature;

  closeDialog: () => void;

  photos: Gallery[];

  documents: DocumentLibrary[];

  showReportDialog?: (features: SGDEFeature[]) => void;
};

const LinkBuilding = ({
  report,
  additionalReportFields,
  feature,
  measurement,
  closeDialog,
  photos,
  documents,
  existingMeasurement,
  map,
}: Props) => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const { openSnackbar } = useSnackbar();
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);

  useEffect(() => {
    modalDispatch?.({
      type: 'actions',
      actions: [
        {
          content: (
            <Button startIcon={<SaveIcon />} key="addTerain" onClick={onReportSave} color="primary">
              Salveaza
            </Button>
          ),
        },
      ],
    });
  }, [selectedReport, photos, documents, additionalReportFields]);

  const onReportSave = async () => {
    let createdMeasurement: AreaMeasurement;
    let measurementId = existingMeasurement?.parentMeasurementId;
    let measurementArea = existingMeasurement?.area;

    if (!existingMeasurement) {
      const measurementResult = await fetch(`${GIS_CONFIG.BASE_API_URL}/measurements`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(measurement),
      });

      createdMeasurement = await measurementResult.json();
      measurementArea = createdMeasurement.area;
      measurementId = createdMeasurement.parentMeasurementId!;
    }

    const galleryFields: ReportFieldOptional[] = [];
    for (const galleryFieldId in photos) {
      const galleryPhotos = photos[galleryFieldId];
      const photosFormData = new FormData();
      if (galleryPhotos?.files?.length > 0) {
        for (const photo of galleryPhotos.files) {
          photosFormData.append(photo.name, photo);
        }
      }

      const galleryResult = await fetch(`${GIS_CONFIG.BASE_API_URL}/gallery/-1`, {
        method: 'POST',
        body: photosFormData,
      });

      const gallery = await galleryResult.json();

      const galleryReportField = {
        reportFieldDefinitionId: parseInt(galleryFieldId),
        value: String(gallery?.mediaGalleryId),
      };

      galleryFields.push(galleryReportField);
    }

    const documentsReportFields: ReportFieldOptional[] = [];
    for (const documentFieldId in documents) {
      const documentLibrary = documents[documentFieldId];
      const documentsFormData = new FormData();
      if (documentLibrary?.files?.length > 0) {
        for (const document of documentLibrary.files) {
          documentsFormData.append(document.name, document);
        }
      }

      const libraryResult = await fetch(`${GIS_CONFIG.BASE_API_URL}/documents/-1`, {
        method: 'POST',
        body: documentsFormData,
      });

      const library = await libraryResult.json();

      const documentsReportField = {
        reportFieldDefinitionId: parseInt(documentFieldId),
        value: String(library?.library?.id),
      };

      documentsReportFields.push(documentsReportField);
    }

    const reportToCreate = {
      ...report,
      reportFields: [
        ...additionalReportFields,
        ...documentsReportFields,
        ...galleryFields,
        {
          reportFieldDefinitionId: 36,
          value: String(true),
        },
        {
          reportFieldDefinitionId: 1,
          value: String(measurementArea),
        },
        {
          reportFieldDefinitionId: 35,
          value: String(measurementId),
        },
      ],
    };

    reportToCreate.parentReportId = selectedReport?.id;
    const result = await fetch(`${GIS_CONFIG.BASE_API_URL}/reports`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reportToCreate),
    });

    if (result.status !== 200) {
      openSnackbar({ severity: 'error', message: 'Eroare la crearea constructiei' });
    }

    const createdReport: Report = await result.json();

    const areaMeasurement = feature.getAreaMeasurement();
    if (!createdReport?.id) {
      return;
    }

    await postResource(
      `${GIS_CONFIG.BASE_API_URL}/measurements/${measurementId}/report/${createdReport.id}?area=${areaMeasurement.area}&centerLat=${areaMeasurement.lat}&centerLng=${areaMeasurement.lng}`
    );

    map?.reloadMeasurementsLayer(ReportType.Constructie);
    map?.reloadMeasurementsLayer(ReportType.Masuratoare);
    modalDispatch?.({ type: 'close' });
    closeDialog();
    openSnackbar({ severity: 'success', message: 'Constructie creata cu succes' });
  };

  const onSelectedReport = (selected: Report) => {
    setSelectedReport(selected);
  };

  return (
    <form>
      <Grid container>
        <Grid item xs={12}>
          <SearchReport reportTypes={[1]} onSelectedReport={onSelectedReport}></SearchReport>
        </Grid>
      </Grid>
    </form>
  );
};

export default LinkBuilding;
