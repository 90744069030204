import { FormControlLabel, Radio, RadioGroup } from '@sgde/core';
import { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  options: string[];

  defaultValue: string;

  fieldId: number;

  onChange: (changedValue: { target: { value: string } }) => void;

  readonly: boolean;
};

const BinaryOptions = ({ defaultValue, options, fieldId, onChange, readonly }: Props) => {
  const [value, setValue] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(e.target.value);
    onChange && onChange({ target: { value: newValue } });
  };

  useEffect(() => {
    setValue(defaultValue || options[0]);
  }, [defaultValue]);

  return (
    <>
      <RadioGroup value={value} onChange={handleChange}>
        {options.map((option, index) => (
          <FormControlLabel
            style={{ textTransform: 'capitalize' }}
            key={`binary-option-${fieldId}-${index}`}
            disabled={readonly}
            value={option}
            control={<Radio />}
            label={option}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default BinaryOptions;
