import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, useSnackbar } from '@sgde/core';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ReportType } from '../../models/ReportType';
import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { AreaMeasurement } from '../../models/measurement.model';
import { ReportFieldOptional } from '../../models/report-field.model';
import { ProgressContext } from '../../primitives/Progress/Progress';
import { useCreateReport } from '../../store/slices/reportsApi';
import useStore from '../../store/useStore';
import DynamicForm from '../Form/DynamicForm/DynamicForm';

type Props = {
  map: OpenLayersMap;
  feature: SGDEFeature;
  existingMeasurement?: AreaMeasurement;
  closeDialog: () => void;
  showReportDialog?: (features: SGDEFeature[]) => void;
};

const CreateParcel = ({ feature, existingMeasurement, closeDialog, map }: Props) => {
  const { dispatch: progress } = useContext(ProgressContext);
  const { openSnackbar } = useSnackbar();
  const [additionalReportFields, setAdditionalReportFields] = useState<ReportFieldOptional[]>([]);
  const [name, setName] = useState('');
  const [error, setError] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const addMeasurement = useStore(state => state.addMeasurement);
  const [createReport, { isSuccess, isError }] = useCreateReport();

  useEffect(() => {
    if (isSuccess) {
      map.reloadMeasurementsLayer(ReportType.Parcela);
      closeDialog();
      openSnackbar({ severity: 'success', message: 'Parcela creata cu succes' });
      progress?.('hide');
    } else if (isError) {
      openSnackbar({ severity: 'error', message: 'Eroare la crearea masuratorii' });
      progress?.('hide');
    }
  }, [isSuccess, isError]);

  const onSave = async () => {
    progress?.('show');
    let measurement = existingMeasurement;
    let measurementReportFieldValue = existingMeasurement?.parentMeasurementId;

    if (!measurement) {
      measurement = await addMeasurement(feature.getAreaMeasurement());
      measurementReportFieldValue = measurement?.parentMeasurementId;
    }

    createReport({
      reportTypeId: 7,
      createdBy: 1,
      name: name,
      reportFields: [
        {
          reportFieldDefinitionId: 36,
          value: String(true),
        },
        {
          reportFieldDefinitionId: 1,
          value: String(measurement.area),
        },
        {
          reportFieldDefinitionId: 35,
          value: String(measurementReportFieldValue),
        },
        ...additionalReportFields,
      ],
    });
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    if (targetValue && targetValue.length > 0) {
      setError(false);
      return setName(targetValue);
    }

    setError(true);
  };

  return (
    <>
      <DialogTitle>Creaza raport Parcela</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={error}
              required
              onChange={onNameChange}
              variant="outlined"
              margin="normal"
              label="Nume"
            />
            <DynamicForm
              reportTypeId={7}
              onValidityChange={setIsValid}
              readonly={false}
              onValueChanged={setAdditionalReportFields}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={error || !isValid} onClick={onSave}>
          Salveaza
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateParcel;
