import { ChangeEvent, useEffect } from 'react';

import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent } from '@sgde/core';

import { DayForecast } from '../../../models/day-forecast.model';
import { DocumentLibrary } from '../../../models/document-library.model';
import { FormReportFieldDefinition } from '../../../models/form-report-field-definition.model';
import { Gallery } from '../../../models/gallery.model';
import { Inventory } from '../../../models/inventory.model';
import { MultipleFieldDefinition } from '../../../models/multiple-field-definition';
import { ReportFieldGroup } from '../../../models/report-field-group.model';
import { ReportField } from '../../../models/report-field.model';
import BinaryOptions from '../../../primitives/BinaryOption/BinaryOption';
import Documents from '../../../primitives/Documents/Documents';
import MultiYearGallery from '../../../primitives/MultiYearGallery/MultiYearGallery';
import MultiYearInput from '../../../primitives/MultiYearInput/MultiYearInput';
import { BackgroundStyleField } from '../BackgroundStyle/BackgroundStyleField.tsx';
import { Multiple } from '../MultipleForm/Multiple.tsx';
import MultipleGroup from '../MultipleForm/MultipleGroup';
import { NativeDateTimePicker } from '../NativeDatePicker/NativeDatePicker';
import { ReportTextField } from '../TextField';
import { useStyles } from './DynamicField.styles.tsx';

interface IProps {
  field: FormReportFieldDefinition;
  allFields: FormReportFieldDefinition[];
  allGroups: ReportFieldGroup[];
  multiFields?: MultipleFieldDefinition[];
  reportFields?: ReportField[];
  readonly?: boolean;
  onChange?: (fieldDefinition: FormReportFieldDefinition) => void;
  onGalleryChanged?: (fieldId: number, gallery: Gallery) => void;
  onDocumentsChange?: (documentLibraryId: number, documents: DocumentLibrary) => void;
  onMultiYearChange?: (inventory: Inventory) => void;
  forecast?: DayForecast[];
}

export const DynamicField = ({
  field: originalField,
  allFields,
  allGroups,
  multiFields,
  reportFields,
  readonly,
  onChange,
  onDocumentsChange,
  onMultiYearChange,
  onGalleryChanged,
  forecast,
}: IProps) => {
  const { classes } = useStyles();
  const field = structuredClone(originalField);
  const handleChange = useOnChange(field, onChange);
  const booleanValue = field.value === true || field.value === 'true' || field.value === 'True';

  useEffect(() => {
    switch (field.reportFieldType.name) {
      case 'Dropdown':
        handleChange({ target: { value: field.value?.toString() || field.reportFieldKeyValuePairs?.[0]?.key } });
        return;
      case 'Options':
        handleChange({ target: { value: field.value?.toString() || field.arguments.split(';')[0] } });
        return;
    }
  }, []);

  switch (field.reportFieldType.name) {
    case 'Double':
      return (
        <ReportTextField key={field.id} report={field} type="number" disabled={readonly} onChange={handleChange} />
      );
    case 'Boolean':
      return (
        <FormControlLabel
          key={field.id}
          label={field.fieldName}
          control={
            <Checkbox
              id={field.id.toString()}
              name={field.id.toString()}
              value={booleanValue}
              color="primary"
              disabled={readonly}
              checked={booleanValue}
              onChange={handleChange}
            />
          }
        />
      );
    case 'Dropdown':
      return (
        <FormControl key={field.id} className={classes.formControl}>
          <InputLabel>{field.fieldName}</InputLabel>
          <Select
            fullWidth
            name={field.id.toString()}
            id={field.id.toString()}
            label={field.fieldName}
            value={field.value?.toString() || field.reportFieldKeyValuePairs?.[0]?.key}
            disabled={readonly}
            onChange={handleChange}
          >
            {[...field.reportFieldKeyValuePairs]
              .sort((a, b) => (a.value > b.value ? 1 : -1))
              .map(reportFieldKeyValuePair => (
                <MenuItem key={reportFieldKeyValuePair.key} value={reportFieldKeyValuePair.key}>
                  {reportFieldKeyValuePair.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    case 'String':
      return <ReportTextField key={field.id} report={field} disabled={readonly} onChange={handleChange} />;
    case 'MultilineString':
      return <ReportTextField key={field.id} report={field} disabled={readonly} multiline onChange={handleChange} />;
    case 'Gallery':
      return (
        <MultiYearGallery
          key={field.id}
          name={field.fieldName}
          readonly={!!readonly}
          galleryId={Number(field.value)}
          onGalleryChanged={files => onGalleryChanged?.(field.id, files)}
        />
      );
    case 'Inventory':
      return (
        <MultiYearInput
          key={field.id}
          name={field.fieldName}
          readonly={!!readonly}
          inventoryId={Number(field.value)}
          onChange={inventory => onMultiYearChange?.(inventory)}
        />
      );
    case 'Options':
      return (
        <BinaryOptions
          options={field.arguments.split(';')}
          key={field.id}
          readonly={!!readonly}
          defaultValue={field.value?.toString() || ''}
          fieldId={field.id}
          onChange={handleChange}
        />
      );
    case 'DocumentLibrary':
      return (
        <Documents
          key={field.id}
          reportFieldId={field.id}
          name={field.fieldName}
          readonly={!!readonly}
          documentLibraryId={Number(field.value)}
          onDocumentsChange={files => onDocumentsChange?.(field.id, files)}
        />
      );
    case 'DateTime':
      return (
        <FormControl key={field.id} className={classes.formControl}>
          <NativeDateTimePicker
            key={field.id}
            reportFieldId={field.id}
            name={field.fieldName}
            disabled={!!readonly}
            onChange={handleChange}
            value={typeof field.value === 'string' ? field.value : undefined}
          />
        </FormControl>
      );
    case 'BackgroundStyle':
      return <BackgroundStyleField onChange={handleChange} styleId={Number(field.value)} disabled={!!readonly} />;
    case 'Multiple':
      return (
        <Multiple
          key={field.id}
          parentField={field}
          allFields={allFields}
          allGroups={allGroups}
          multiFields={multiFields}
          readonly={readonly}
          onChange={onChange}
          onDocumentsChange={onDocumentsChange}
          onGalleryChanged={onGalleryChanged}
          onMultiYearChange={onMultiYearChange}
          forecast={forecast}
        />
      );
    case 'MultipleGroup':
      return (
        <MultipleGroup
          key={field.id}
          readonly={readonly}
          parentField={field}
          allFields={allFields}
          allGroups={allGroups}
          multiFields={multiFields}
          reportFields={reportFields}
          onChange={onChange}
        />
      );
    default:
      return <></>;
  }
};

const useOnChange =
  (field: FormReportFieldDefinition, onChange?: (fieldDefinition: FormReportFieldDefinition) => void) =>
  (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string | number | boolean>
      | { target: { value: string } }
  ) => {
    const type = field.reportFieldType.name;
    if (type === 'Boolean') {
      const htmlInputEvent = event as ChangeEvent<HTMLInputElement>;
      field.value = htmlInputEvent.target.checked;
    } else {
      field.value = event.target.value;
    }

    if (field.validationRegex) {
      if (!field.value.toString().match(field.validationRegex)) {
        field.helperText = field.validationRegexMessage;
        field.error = true;
      } else {
        field.error = false;
        field.helperText = '';
      }
    }
    onChange?.(field);
  };
