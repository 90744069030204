import { baseApi } from '@sgde/core';
import TryLinkMeasurements from '../../models/TryLinkMeasurements';
import { AreaMeasurement } from '../../models/measurement.model';
import { ReportField } from '../../models/report-field.model';

const measurementsApi = baseApi.enhanceEndpoints({ addTagTypes: ['Measurements'] }).injectEndpoints({
  endpoints: build => ({
    getMeasurementsById: build.query<AreaMeasurement[], string>({
      query: id => `/measurements/${id}/area-measurements`,
      providesTags: (_result, _error, id) => [{ type: 'Measurements', id }],
    }),
    getMeasurementById: build.query<AreaMeasurement, string>({
      query: id => `/measurements/area-measurements/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Measurements', id }],
    }),
    addMeasurement: build.mutation<void, { id: number; measurement: Omit<AreaMeasurement, 'id' | 'reportTypeId'> }>({
      query: ({ id, measurement: body }) => ({ url: `/measurements/${id}`, method: 'PUT', body }),
    }),
    deleteMeasurements: build.mutation<void, number[]>({
      query: ids => ({ url: `/measurements/${ids.join(',')}`, method: 'DELETE' }),
      invalidatesTags: (_, __, ids) => [{ type: 'Measurements', ...ids }],
    }),
    addMeasurementFields: build.mutation<
      void,
      { id: number; fields: Pick<ReportField, 'reportFieldDefinitionId' | 'value'>[] }
    >({
      query: ({ id, fields: body }) => ({ url: `/reports/${id}/fields`, method: 'PUT', body }),
    }),
    linkMeasurement: build.mutation<
      void,
      { measurementId: number; reportId: number; areaMeasurement: { area: number; lat: number; lng: number } }
    >({
      query: ({ measurementId, reportId, areaMeasurement }) => ({
        url: `/measurements/${measurementId}/report/${reportId}?area=${areaMeasurement.area}&centerLat=${areaMeasurement.lat}&centerLng=${areaMeasurement.lng}`,
        method: 'POST',
      }),
    }),
    tryLinkMeasurementAndCreateReport: build.mutation<void, TryLinkMeasurements>({
      query: tryLinkMeasurementsRequest => {
        return { url: `measurements/link`, method: 'POST', body: tryLinkMeasurementsRequest };
      },
    }),
  }),
});

export const {
  useGetMeasurementsByIdQuery: useMeasurementsById,
  useGetMeasurementByIdQuery: useMeasurementById,
  useAddMeasurementMutation: useAddMeasurement,
  useDeleteMeasurementsMutation: useDeleteMeasurements,
  useAddMeasurementFieldsMutation: useAddMeasurementFields,
  useLinkMeasurementMutation: useLinkMeasurement,
  useTryLinkMeasurementAndCreateReportMutation: useTryLinkMeasurementsAndCreateReport,
} = measurementsApi;
