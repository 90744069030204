import { TabContext, TabList } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, PushPinIcon, Tab, Tabs, useSnackbar } from '@sgde/core';
import { Extent, extend } from 'ol/extent';
import { Projection } from 'ol/proj';
import { Fill, Style } from 'ol/style';
import { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SGDEFeature from '../../models/feature/sgde-feature';
import { Report } from '../../models/report.model';
import PreviewMap from '../../primitives/Map/PreviewMap';
import { ModalContext } from '../../primitives/Modal/ModalContext';
import { ProgressContext } from '../../primitives/Progress/Progress';
import { useApplyCropRotation, useCreateCropRotation } from '../../store/slices/cropRotationApi';

type Props = {
  reports: Report[];
  features?: SGDEFeature[];
  closeDialog: () => void;
};

const epsg3844 = new Projection({
  code: 'EPSG:3844',
  extent: [116394.92, 226748.13, 975734.46, 771888.93],
});

const CreateCropRotation = ({ reports, features, closeDialog }: Props) => {
  const { dispatch: modal } = useContext(ModalContext);
  const { openSnackbar } = useSnackbar();

  const [
    createCropRotation,
    {
      isError: isErrorOnGeneration,
      isSuccess: isSuccessOnGeneration,
      isLoading: isLoadingGeneration,
      data: cropProposals,
    },
  ] = useCreateCropRotation();
  const [applyCropRotation, { isError: isErrorOnApply, isSuccess: isSuccessOnApply, isLoading: isLoadingApply }] =
    useApplyCropRotation();

  const [selectedVariant, setSelectedVariant] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear() + 1);
  const [localFeatures, setLocalFeatures] = useState(features);
  const [mapExtent, setMapExtent] = useState<Extent>();
  const { dispatch: progress } = useContext(ProgressContext);

  const handleVariantChange = useCallback(
    (_: SyntheticEvent, variantIndex: number) => {
      setSelectedVariant(variantIndex);
      handleYearChange(new Date().getFullYear() + 1, variantIndex);
    },
    [localFeatures, setLocalFeatures, cropProposals]
  );

  const handleYearTabChange = (_: SyntheticEvent, year: number) => {
    handleYearChange(year);
  };

  const handleYearChange = useCallback(
    (year: number, variant?: number) => {
      variant = variant ?? selectedVariant;
      for (const reportId in cropProposals) {
        const cropRotation = cropProposals?.[reportId]?.at(variant);
        if (!cropRotation) {
          continue;
        }

        const feature = localFeatures?.find(f => f.reportId === Number(reportId));
        if (!feature) {
          continue;
        }

        const cropYear = cropRotation.crops.find(c => c.year === Number(year));
        if (!cropYear) {
          continue;
        }

        feature.setStyle(
          new Style({
            fill: new Fill({
              color: cropYear?.color,
            }),
          })
        );

        feature.toolTip = cropYear.crop;
      }

      setSelectedYear(year);
    },
    [localFeatures, selectedVariant, cropProposals]
  );

  useEffect(() => {
    createCropRotation({
      reportsIds: reports.map(r => r.id!),
      variants: 4,
    });
  }, [reports, features]);

  useEffect(() => {
    if (!features) {
      return;
    }

    const extent = features[0].getGeometry()?.getExtent().slice(0);
    if (!extent) {
      return;
    }

    features.forEach(f => {
      const geometry = f.getGeometry();
      if (geometry) {
        extend(extent, geometry.getExtent());
      }
    });

    setMapExtent(extent);
  }, [features]);

  useEffect(() => {
    handleYearChange(new Date().getFullYear() + 1);
  }, [cropProposals]);

  useEffect(() => {
    if (isSuccessOnApply) {
      modal?.({ type: 'close' });
      closeDialog();
      openSnackbar({ severity: 'success', message: 'Asolament aplicat cu success!' });
      progress?.('hide');
      return;
    }

    if (isErrorOnApply) {
      openSnackbar({ severity: 'error', message: 'Eroare la aplicarea asolamentului!' });
      return;
    }

    progress?.(isLoadingApply ? 'show' : 'hide');
  }, [isErrorOnApply, isSuccessOnApply, isLoadingApply]);

  useEffect(() => {
    if (isErrorOnGeneration) {
      openSnackbar({ severity: 'error', message: 'Eroare la generarea asolamentului!' });
      return;
    }
  }, [isErrorOnGeneration]);

  const tabs = useMemo(() => {
    const tabElements = [];
    for (let variant = 0; variant < reports.length; variant++) {
      const label = `Varianta ${variant + 1}`;
      tabElements.push(<Tab label={label} value={variant.toString()} />);
    }

    setSelectedVariant(0);
    return tabElements;
  }, [reports, setSelectedVariant]);

  const yearTabs = useMemo(() => {
    if (!cropProposals) {
      return <></>;
    }

    const firstReport = reports[0];
    if (!firstReport) {
      return <></>;
    }

    const years = cropProposals[firstReport.id!][0].crops.map(c => c.year);
    return years.map(y => <Tab label={y.toString()} value={y.toString()} />);
  }, [cropProposals]);

  const onApplyCropRotation = useCallback(() => {
    if (!cropProposals) {
      return;
    }

    const proposalsToApply = Object.keys(cropProposals).map(reportId => {
      const proposals = cropProposals[reportId];
      const selectedProposal = proposals[selectedVariant];
      return selectedProposal;
    });

    applyCropRotation(proposalsToApply);
  }, [selectedVariant, cropProposals]);

  return (
    <>
      <DialogContent style={{ marginTop: '30px' }}>
        {isLoadingGeneration && (
          <>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px 0' }}>
              <img src="/images/plant-growing.gif" />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px 0' }}>
              <img style={{ width: '50%' }} src="/images/connecting-with-agro-chat.jpg" />
            </Box>
          </>
        )}
        {isSuccessOnGeneration && (
          <Box sx={{ width: '100%' }}>
            <TabContext value={selectedVariant?.toString()}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleVariantChange} aria-label="basic tabs example">
                  {tabs}
                </TabList>
              </Box>
            </TabContext>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
              <Tabs
                orientation="vertical"
                variant="standard"
                value={selectedYear?.toString()}
                onChange={handleYearTabChange}
              >
                {yearTabs}
              </Tabs>
              {mapExtent && (
                <PreviewMap
                  id="preview-crop-rotation-map"
                  extent={mapExtent}
                  projection={epsg3844}
                  features={localFeatures}
                />
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isSuccessOnGeneration && (
          <Button startIcon={<PushPinIcon />} color="secondary" onClick={onApplyCropRotation}>
            Aplica asolament
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default CreateCropRotation;
