// TODO: DELETE!!!

import { useStyles } from './Popup.style';

type Props = {
  containerId?: string;
  contentId?: string;
};

export const Popup = ({ containerId = 'popup', contentId = 'popup-content' }: Props) => {
  const { classes } = useStyles();
  return (
    <div id={containerId} className={classes.ol_popup}>
      <div id={contentId}></div>
    </div>
  );
};
