import { Autocomplete, Box, TextField } from '@sgde/core';
import { useEffect, useMemo, useState } from 'react';
import {
  inputFieldIdsForTotals,
  totalFields,
  totalSumParentFields,
  yearGroupFieldId,
} from '../../../constants/parcel/technical-data.constant';
import { SumParentField } from '../../../constants/sum-parent-field';
import { MultipleItemDto } from '../../../models/DTO/multiple/multipleItemDTO';
import { FormReportFieldDefinition } from '../../../models/form-report-field-definition.model';
import { MultipleFieldDefinition } from '../../../models/multiple-field-definition';
import { ReportFieldGroup } from '../../../models/report-field-group.model';
import { ReportField } from '../../../models/report-field.model';
import { useMultipleItems } from '../../../store/slices/reportFieldsDefinitionApi';
import { DynamicField } from '../DynamicForm/DynamicField';
import DynamicFormGroup from '../DynamicForm/DynamicFormGroup';
import { useStyles } from './MultipleGroup.styles';

interface IProps {
  reportFields?: ReportField[];
  parentField: FormReportFieldDefinition;
  allFields: FormReportFieldDefinition[];
  allGroups: ReportFieldGroup[];
  multiFields?: MultipleFieldDefinition[];
  readonly?: boolean;
  onChange?: (fieldDefinition: FormReportFieldDefinition) => void;
}

const MultipleGroup = ({
  reportFields,
  parentField,
  allFields,
  allGroups,
  multiFields,
  readonly,
  onChange,
}: IProps) => {
  const { classes } = useStyles();
  const [currentGroup, setCurrentGroup] = useState<string>('');
  const [groups, setGroups] = useState<string[]>([]);
  const [multiGroups, setMultiGroups] = useState<Partial<MultipleItemDto>[]>([]);
  const { data: multiItems } = useMultipleItems(
    { multipleId: parentField.multipleId! },
    { skip: !parentField.multipleId }
  );

  useEffect(() => {
    if (multiItems) {
      setMultiGroups(multiItems);
      const groups = [...new Set(multiItems.map(i => i.key))];
      setGroups(groups);
      setCurrentGroup(groups[0]);
      onChange?.({ ...parentField, value: JSON.stringify(multiItems) });
    }
  }, [multiItems]);

  const isReadOnly = (fieldId: number) => (readonly || !currentGroup) && fieldId !== yearGroupFieldId;

  const multipleChildFields = allFields.filter(fieldDefinition =>
    multiFields?.some(
      multiField =>
        multiField.parentReportFieldDefinitionId === parentField.id &&
        fieldDefinition.id === multiField.childReportFieldDefinitionId
    )
  );

  const filteredGroupFields = useMemo(
    () =>
      allGroups.map(group => {
        const filteredFields = multipleChildFields.filter(field => field.reportFieldGroupId === group.id);
        return {
          ...group,
          fields: filteredFields.map(field => ({
            ...field,
            value: multiGroups?.find(
              x =>
                x.key === currentGroup &&
                x.multipleFieldsDefinitionId === multiFields?.find(y => y.childReportFieldDefinitionId === field.id)?.id
            )?.value,
            multipleId: multiGroups?.find(
              x =>
                x.key === currentGroup &&
                x.multipleFieldsDefinitionId === multiFields?.find(y => y.childReportFieldDefinitionId === field.id)?.id
            )?.multipleValueId,
          })),
        };
      }),
    [multiGroups, currentGroup]
  );

  const getSum = (
    totalSumData: SumParentField,
    currentNewValues: Partial<MultipleItemDto>[],
    currentYearGroup: string
  ) => {
    const groups = currentNewValues.filter(
      currentGroup =>
        totalSumData.groupId.includes(currentGroup.multipleFieldsDefinitionId ?? 0) &&
        currentGroup.key === currentYearGroup
    );

    const sum = groups.reduce((acc, group) => {
      if (group?.value) {
        const valueObjects = JSON.parse(group.value);
        const groupSum = valueObjects
          .filter((valueObj: any) => totalSumData.inputFieldIds.includes(valueObj.multipleFieldsDefinitionId))
          .reduce((groupAcc: number, valueObj: any) => groupAcc + Number(valueObj.value), 0);
        return acc + groupSum;
      }
      return acc;
    }, 0);

    return sum;
  };

  const getTotals = (currentNewValues: Partial<MultipleItemDto>[], currentYearGroup = '') => {
    const totalFieldsIds = totalFields.map(total => total.multipleFieldsDefinitionId);
    const currentWithNoTotals = currentNewValues.filter(
      currentField =>
        currentField?.multipleFieldsDefinitionId &&
        !(totalFieldsIds.includes(currentField.multipleFieldsDefinitionId) && currentField.key === currentYearGroup)
    );
    const totals = totalFields.map(totalValue => {
      const totalSumData = totalSumParentFields.find(
        item => item.totalFieldId === totalValue.multipleFieldsDefinitionId
      );
      const total = totalSumData ? getSum(totalSumData, currentWithNoTotals, currentYearGroup) : 0;
      return { ...totalValue, value: total.toString(), key: currentYearGroup };
    });

    return [...currentWithNoTotals, ...totals];
  };

  const handleChange = (fieldDefinition: FormReportFieldDefinition) => {
    const multipleItem = {
      key: currentGroup,
      value: fieldDefinition.value,
      multipleId: parentField.multipleId,
      multipleFieldsDefinitionId: multiFields?.find(
        multiField => multiField.childReportFieldDefinitionId === fieldDefinition.id
      )?.id,
    } as Partial<MultipleItemDto>;
    setMultiGroups(multiGroups => {
      const newValues = multiGroups.some(
        f => f.multipleFieldsDefinitionId === multipleItem.multipleFieldsDefinitionId && f.key === multipleItem.key
      )
        ? multiGroups.reduce((result, current) => {
            if (
              current.multipleFieldsDefinitionId === multipleItem.multipleFieldsDefinitionId &&
              current.key === multipleItem.key
            ) {
              result.push({ ...current, value: multipleItem.value });
            } else {
              result.push(current);
            }
            return result;
          }, [] as Partial<MultipleItemDto>[])
        : [...multiGroups, multipleItem];

      const newValuesWithTotals =
        multipleItem?.multipleFieldsDefinitionId &&
        inputFieldIdsForTotals.includes(multipleItem?.multipleFieldsDefinitionId)
          ? getTotals(newValues, currentGroup)
          : newValues;
      setGroups([...new Set(newValuesWithTotals.map(i => i.key!))]);
      onChange?.({ ...parentField, value: JSON.stringify(newValuesWithTotals) });
      return newValuesWithTotals;
    });
  };

  const getExpandedItems = (fieldGroup: ReportFieldGroup) => {
    return (
      <Box component="div" className={classes.formItem}>
        {fieldGroup.fields.map(field => {
          return (
            <DynamicField
              key={field.id}
              allFields={allFields}
              allGroups={allGroups}
              onChange={handleChange}
              field={field}
              reportFields={reportFields}
              readonly
            />
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <div>
        <Autocomplete
          freeSolo
          disableClearable
          onChange={(_, value) => setCurrentGroup(value)}
          value={currentGroup}
          options={groups}
          getOptionLabel={option => option.toString()}
          renderInput={params => (
            <TextField
              {...params}
              label={parentField.fieldName}
              InputProps={{
                ...params.InputProps,
                onChange: ({ currentTarget: { value } }) => setCurrentGroup(value),
              }}
            />
          )}
        />

        {filteredGroupFields.map(
          fieldGroup =>
            !!fieldGroup.fields.length && (
              <div key={`formGroup-${fieldGroup.id}`}>
                {fieldGroup.id !== parentField.reportFieldGroupId && <h4>{fieldGroup.name}</h4>}
                {fieldGroup.defaultExpanded ? (
                  getExpandedItems(fieldGroup)
                ) : (
                  <DynamicFormGroup
                    key={`accordion-group-field-${parentField.groupTitle}`}
                    reportFields={reportFields}
                    groupElements={fieldGroup.fields}
                    allFields={allFields}
                    allGroups={allGroups}
                    multiFields={multiFields}
                    formGroup={parentField}
                    readonly={isReadOnly(fieldGroup.id)}
                    onValueChanged={handleChange}
                  />
                )}
              </div>
            )
        )}
      </div>
    </>
  );
};

export default MultipleGroup;
