import { Button, DialogActions, DialogContent, DialogTitle, Grid, SaveIcon, useSnackbar } from '@sgde/core';
import { useEffect, useState } from 'react';
import { Report } from '../../models/report.model';
import { useUpdateReport } from '../../store/slices/reportsApi';
import SearchReport from '../SearchReport/SearchReport';

type Props = {
  report: Report;
  reportType: number;
  closeDialog: () => void;
};

const ReportLink = ({ report, reportType, closeDialog }: Props) => {
  const [selectedReport, setSelectedReport] = useState<Report>();
  const { openSnackbar } = useSnackbar();
  const [updateReport, { isSuccess, isError }] = useUpdateReport();

  useEffect(() => {
    if (isSuccess) {
      openSnackbar({ severity: 'success', message: 'Raport actualizat!' });
      closeDialog();
    }
    if (isError) {
      openSnackbar({ severity: 'error', message: 'Eroare la actualizarea raportului!' });
      closeDialog();
    }
  }, [isSuccess, isError]);

  return (
    <>
      <DialogTitle>Legare fisa</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <SearchReport reportTypes={[reportType]} onSelectedReport={setSelectedReport} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {selectedReport && (
          <Button
            startIcon={<SaveIcon />}
            key="addTerain"
            onClick={() => updateReport({ ...report, parentReportId: selectedReport?.id })}
            color="primary"
          >
            Salveaza
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default ReportLink;
