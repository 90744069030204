import { SetState, StateCreator } from 'zustand';
import { ReportType } from '../../../models/ReportType';
import { AreaMeasurement } from '../../../models/measurement.model';
import { postResource } from '../../Fetch.ts';
import { GIS_CONFIG } from '../../settings/Local.ts';
import { GlobalState } from '../../useStore.ts';

export interface TerrainMeasurementsState {
  terrainMeasurements: AreaMeasurement[];

  latestTerrainMeasurements: AreaMeasurement[];

  getTerrainMeasurements: (
    bounds?: number[][],
    customGeometry?: boolean,
    skip?: number,
    publicPageName?: string
  ) => void;

  cleanTerrainMeasurements: () => void;
}

const createTerrainMeasurementsSlice: StateCreator<TerrainMeasurementsState, SetState<GlobalState>> = (set, get) => ({
  terrainMeasurements: [],
  latestTerrainMeasurements: [],
  getTerrainMeasurements: async (bounds, customGeometry = false, skip = 0, publicPageName = '') => {
    const { measurements: latestTerrainMeasurements, moreAvailable } = await postResource(
      `${GIS_CONFIG.BASE_API_URL}/constructii/measurements?reportTypes=${ReportType.Imobil}&skip=${skip}&take=1000&customGeometry=${customGeometry}&publicLink=${publicPageName}`,
      bounds
    );

    const terrainMeasurements = [...get().terrainMeasurements, ...(latestTerrainMeasurements || [])];
    set({ terrainMeasurements });
    set({ latestTerrainMeasurements });
    const newSkip = skip + 1000;
    if (moreAvailable) {
      await get().getTerrainMeasurements(bounds, customGeometry, newSkip, publicPageName);
    }
  },
  cleanTerrainMeasurements: () => {
    set({ latestTerrainMeasurements: [] });
  },
});

export default createTerrainMeasurementsSlice;
