import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(() => ({
  root: {
    maxWidth: 280,
    display: 'flex',
    gap: 24,
    flexDirection: 'column',
    '& button': {
      width: 'fit-content',
    },
  },
}));
