import { Button } from '@sgde/core';
import { ReactNode, useState } from 'react';
import ConfirmationDialog from '../Modal/ConfirmationDialog';
import MultiDialog from '../Modal/Dialog';

type Props = {
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  title: string;
  message: string;
  startIcon: ReactNode;
  children: string;
  onConfirm: () => void;
};

const ConfirmationButton = ({ children, color, title, message, startIcon, onConfirm }: Props) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  return (
    <>
      <Button color={color} startIcon={startIcon} onClick={() => setShowConfirmationDialog(true)}>
        {children}
      </Button>
      <MultiDialog onClose={() => setShowConfirmationDialog(false)} open={showConfirmationDialog}>
        <ConfirmationDialog title={title} message={message} confirm={onConfirm}></ConfirmationDialog>
      </MultiDialog>
    </>
  );
};

export default ConfirmationButton;
