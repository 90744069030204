import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(() => ({
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  formItem: {
    gap: 10,
    display: 'flex',
  },
}));
