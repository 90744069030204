import { baseApi } from '@sgde/core';
import {
  BackgroundStyleDTO,
  BackgroundStyleFileDTO,
  CreateBackgroundStyle,
} from '../../models/DTO/styling/BackgroundStyleDTO';

const backgroundStylesApi = baseApi.enhanceEndpoints({ addTagTypes: ['BackgroundStyles'] }).injectEndpoints({
  endpoints: build => ({
    createBackgroundStyleColor: build.mutation<void, CreateBackgroundStyle>({
      query: backgroundStyle => {
        return { url: 'styles/background/color', method: 'POST', body: backgroundStyle };
      },
      invalidatesTags: ['BackgroundStyles'],
    }),
    createBackgroundStyleImage: build.mutation<void, BackgroundStyleFileDTO>({
      query: backgroundStyle => {
        const formData = new FormData();
        formData.append(backgroundStyle.imageBlob!.name, backgroundStyle!.imageBlob!);
        formData.append('Name', backgroundStyle.name);
        return { url: 'styles/background/image', method: 'POST', body: formData };
      },
      invalidatesTags: ['BackgroundStyles'],
    }),
    getBackgroundStyles: build.query<BackgroundStyleDTO[], void>({
      query: () => 'styles/background',
      providesTags: ['BackgroundStyles'],
    }),
  }),
});

export const {
  useCreateBackgroundStyleColorMutation: useCreateBackgroundStyleColor,
  useCreateBackgroundStyleImageMutation: useCreateBackgroundStyleImage,
  useGetBackgroundStylesQuery: useGetBackgroundStyles,
} = backgroundStylesApi;
