import { baseApi } from '@sgde/core';
import CreateCropRotationDto from '../../models/create-crop-rotation.model';

const cropRotationApi = baseApi.enhanceEndpoints({ addTagTypes: ['CropRotation'] }).injectEndpoints({
  endpoints: build => ({
    createCropRotation: build.mutation<CropProposals, CreateCropRotationDto>({
      query: createCropRotation => {
        return { url: `/crop-rotation`, method: 'POST', body: createCropRotation };
      },
      invalidatesTags: ['CropRotation'],
    }),
    applyCropRotation: build.mutation<void, CropProposal[]>({
      query: applyCropRotation => {
        return { url: `/crop-rotation/apply`, method: 'POST', body: applyCropRotation };
      },
      invalidatesTags: ['CropRotation'],
    }),
  }),
});

export const {
  useCreateCropRotationMutation: useCreateCropRotation,
  useApplyCropRotationMutation: useApplyCropRotation,
} = cropRotationApi;
