import { Header, Stack, Typography, useCurrentUser, useHeader } from '@sgde/core';
import { useContext, useEffect } from 'react';
import { MapSettingsState } from '../../store/slices/mapSettingsSlice';
import { SettingsState } from '../../store/slices/settingsSlice';
import useStore from '../../store/useStore';
import { Logo } from '../Logo/Logo';
import { ModalContext } from '../Modal/ModalContext';
import { useStyles } from './Menu.styles';
import { MenuItems } from './MenuItems';
import MenuSearch from './MenuSearch';
import { PublicMenuItems } from './PublicMenuItems';

export const AppHeader = () => {
  const { dispatch: modal } = useContext(ModalContext);
  const { data: user } = useCurrentUser();
  const setSettings = useStore((state: SettingsState) => state.setSettings);
  const setZoom = useStore((state: MapSettingsState) => state.setZoom);
  const settings = useStore((state: SettingsState) => state.settings);
  const { classes } = useStyles();

  const { setMenuItems } = useHeader();

  useEffect(() => {
    const menuItems = [
      <Stack direction="row" alignItems="center" justifyContent={'center'} sx={{ flex: 1 }}>
        <Typography variant="h6" className="menu-title">
          {settings.publicPageTitle}
        </Typography>
      </Stack>,
    ];

    // add search to menu just for logged in users or public pages
    if ((user && !user.softDeleted) || settings.isPublic) {
      menuItems.push(<MenuSearch setZoom={setZoom} setSettings={setSettings} settings={settings} modal={modal} />);
    }

    setMenuItems(menuItems);
  }, [setMenuItems, settings, setZoom, setSettings, modal, user]);

  return (
    <Header
      logo={<Logo />}
      elevation={0}
      className={classes.header}
      profileMenuItems={settings.isPublic ? <PublicMenuItems /> : <MenuItems />}
      hideLogin={settings.isPublic}
    />
  );
};
