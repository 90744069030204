import { SetState, StateCreator } from 'zustand';
import Settings from '../../models/settings/settings.model';
import { putResource } from '../Fetch';
import { GIS_CONFIG } from '../settings/Local';
import { GlobalState } from '../useStore.ts';

export interface SettingsState {
  settings: Settings;

  setSettings: (settings: any) => void;

  saveSettings: () => void;
}

const createSettingsSlice: StateCreator<SettingsState, SetState<GlobalState>> = (set, get) => ({
  settings: {
    isReady: false,
    reportTypes: [],
    streetViewYears: [],
    backgroundType: 'ortofotoplan',
  },
  setSettings: newSettings => {
    set({ settings: newSettings });
  },
  saveSettings: async () => {
    const newSettings = get().settings;
    await putResource(`${GIS_CONFIG.BASE_API_URL}/users/settings`, newSettings);
  },
});

export default createSettingsSlice;
