import { baseApi } from '@sgde/core';
import { DocumentLibrary } from '../../models/document-library.model';

const documentsApi = baseApi.enhanceEndpoints({ addTagTypes: ['Documents'] }).injectEndpoints({
  endpoints: build => ({
    updateDocuments: build.mutation<void, DocumentLibrary>({
      query: document => {
        const formData = new FormData();
        document.files.forEach(file => formData.append(file.name, file));
        return { url: `/documents/${document.documentLibraryId}`, method: 'POST', body: formData };
      },
      invalidatesTags: ['Documents'],
    }),
  }),
});

export const { useUpdateDocumentsMutation: useUpdateDocuments } = documentsApi;
