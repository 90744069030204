import {
  AccountBalanceIcon,
  AssessmentIcon,
  CropLandscapeIcon,
  HomeIcon,
  LinearScaleIcon,
  PictureInPictureIcon,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  WaterDamageIcon,
} from '@sgde/core';
import { useState } from 'react';
import { creationModes } from './CreationModes';

type Props = {
  addAction: (creationMode: creationModes) => void;
};

const MapActions = ({ addAction }: Props) => {
  const direction = 'up';

  const [open, setOpen] = useState(false);

  const triggerAddAction = (creationMode: creationModes) => {
    setOpen(false);
    addAction(creationMode);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={direction}
      >
        <SpeedDialAction
          key="addBuilding"
          icon={<HomeIcon />}
          tooltipTitle="Cladire"
          tooltipOpen
          onClick={() => triggerAddAction(creationModes.BUILDING)}
        />
        <SpeedDialAction
          key="AddTerain"
          icon={<PictureInPictureIcon />}
          tooltipTitle="Imobil"
          tooltipOpen
          onClick={() => triggerAddAction(creationModes.TERAIN)}
        />
        <SpeedDialAction
          key="AddPatrimoniu"
          icon={<AccountBalanceIcon />}
          tooltipTitle="Patrimoniu"
          tooltipOpen
          onClick={() => triggerAddAction(creationModes.PATRIMONIU)}
        />
        <SpeedDialAction
          key="AddMeasurement"
          icon={<AssessmentIcon />}
          tooltipTitle="Masuratoare"
          tooltipOpen
          onClick={() => triggerAddAction(creationModes.MEASUREMENT)}
        />
        <SpeedDialAction
          key="AddConducta"
          icon={<LinearScaleIcon />}
          tooltipTitle="Conducta"
          tooltipOpen
          onClick={() => triggerAddAction(creationModes.CONDUCTA)}
        />
        <SpeedDialAction
          key="AddCamin"
          icon={<WaterDamageIcon />}
          tooltipTitle="Camin"
          tooltipOpen
          onClick={() => triggerAddAction(creationModes.CAMIN)}
        />
        <SpeedDialAction
          key="AddParcel"
          icon={<CropLandscapeIcon />}
          tooltipTitle="Parcela"
          tooltipOpen
          onClick={() => triggerAddAction(creationModes.PARCELA)}
        />
      </SpeedDial>
    </div>
  );
};

export default MapActions;
