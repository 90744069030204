import { baseApi } from '@sgde/core';
import { ClaimDTO } from '../../models/DTO/authorization/claimDTO';
import { PolicyDTO } from '../../models/DTO/authorization/policyDTO';
import { ResourceDTO } from '../../models/DTO/authorization/resourceDTO';
import { RoleDTO } from '../../models/DTO/authorization/roleDTO';

const authorizationApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['Roles', 'Claims', 'Policies', 'Resources', 'ClaimsCurrent'] })
  .injectEndpoints({
    endpoints: build => ({
      getCurrentClaims: build.query<ClaimDTO[], void>({
        query: () => '/authorization/claims/current',
        providesTags: ['ClaimsCurrent'],
      }),
      getRoles: build.query<RoleDTO[], void>({
        query: () => '/authorization/roles',
        providesTags: ['Roles'],
      }),
      addRole: build.mutation<void, { name: string }>({
        query: body => ({ url: '/authorization/roles', method: 'POST', body }),
        invalidatesTags: ['Roles'],
      }),
      recoverRoles: build.mutation<void, number[]>({
        query: ids => ({ url: `/authorization/roles/${ids.join(',')}`, method: 'PATCH' }),
        invalidatesTags: ['Roles'],
      }),
      deleteRoles: build.mutation<void, number[]>({
        query: ids => ({ url: `/authorization/roles/${ids.join(',')}`, method: 'DELETE' }),
        invalidatesTags: ['Roles'],
      }),
      getClaims: build.query<ClaimDTO[], void>({
        query: () => '/authorization/claims',
        providesTags: ['Claims'],
      }),
      getPolicies: build.query<PolicyDTO[], void>({
        query: () => '/authorization/policies',
        providesTags: ['Policies'],
      }),
      updatePolicy: build.mutation<void, PolicyDTO>({
        query: policy => ({ url: '/authorization/policies', method: 'POST', body: policy }),
        invalidatesTags: ['Policies'],
      }),
      getResources: build.query<ResourceDTO[], void>({
        query: () => '/authorization/resources',
        providesTags: ['Resources'],
      }),
    }),
  });

export const {
  useGetCurrentClaimsQuery: useCurrentClaims,
  useGetRolesQuery: useRoles,
  useAddRoleMutation: useAddRole,
  useRecoverRolesMutation: useRecoverRoles,
  useDeleteRolesMutation: useDeleteRoles,
  useGetClaimsQuery: useClaims,
  useGetPoliciesQuery: usePolicies,
  useUpdatePolicyMutation: useUpdatePolicy,
  useGetResourcesQuery: useResources,
} = authorizationApi;
