import {
  ContentCopyIcon,
  DeleteIcon,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useSnackbar,
} from '@sgde/core';
import { useContext, useEffect } from 'react';
import { findReportTypeById } from '../../../models/ReportType';
import PublicPageSetting from '../../../models/public-page-model';
import { useDeletePublicPageSettings } from '../../../store/slices/publicPageApi';
import { useAllReportFieldDefinitions } from '../../../store/slices/reportFieldsDefinitionApi';
import { ProgressContext } from '../../Progress/Progress';
import { useStyles } from './PublicPageList.styles';

interface FieldReport {
  reportTypeId: number;
  fieldId: number;
}

const PublicPageListRow = (props: { row: PublicPageSetting }) => {
  const { row } = props;
  const { dispatch: progress } = useContext(ProgressContext);
  const { openSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const { data: reportFieldsDefinitions } = useAllReportFieldDefinitions();
  const [deletePage, { error, isLoading, isSuccess }] = useDeletePublicPageSettings();

  const publicPageRootUrl = `${window.location.origin}/public/`;

  useEffect(() => {
    if (isSuccess) openSnackbar({ severity: 'success', message: 'Pagina public a fost stearsa cu succes' });

    if (error) openSnackbar({ severity: 'error', message: 'Eroare la stergerea paginii' });

    isLoading && progress?.('show');
    !isLoading && progress?.('hide');
  }, [error, isLoading, isSuccess, openSnackbar, progress]);

  const onCopy = (pageLink: string) => {
    navigator.clipboard.writeText(pageLink);
    openSnackbar({ severity: 'success', message: 'Link-ul a fost copiat in clipboard' });
  };

  const renderPageLinkCell = (pageLink: string) => (
    <Stack direction="row" spacing={1} alignItems="center">
      <Link component={'a'} href={`${publicPageRootUrl}${pageLink}`} target="_blank" rel="noreferrer">
        {pageLink}
      </Link>
      <Tooltip title="Copiază link-ul">
        <ContentCopyIcon onClick={() => onCopy(`${publicPageRootUrl}${pageLink}`)} className={classes.copyIcon} />
      </Tooltip>
    </Stack>
  );

  const renderTooltipVisibleFields = (tooltipVisibleFields: number[]) => {
    const grouped: { [key: number]: { fieldId: number; reportTypeId: number }[] } = {};

    tooltipVisibleFields.forEach(id => {
      const reportTypeId = reportFieldsDefinitions?.find(field => field.id === id)?.reportTypeId;

      if (!reportTypeId) return;

      const fieldReport = { fieldId: id, reportTypeId };
      grouped[reportTypeId] = grouped[reportTypeId] || [];
      grouped[reportTypeId].push(fieldReport);
    });

    const result: { group: string; values: string[] }[] = [];

    Object.entries(grouped).forEach(([key, value]) => {
      const group = findReportTypeById(Number(key));
      if (!group) return;
      const values = value.map((val: FieldReport) => {
        const reportField = reportFieldsDefinitions?.find(rfd => rfd.id === val.fieldId);
        return reportField?.fieldName ?? '';
      });
      result.push({ group, values });
    });

    return result;
  };

  return (
    <TableRow>
      <TableCell>{row.pageTitle}</TableCell>
      <TableCell>{renderPageLinkCell(row.pageLink)}</TableCell>
      <TableCell>{row.pageDefaultZoom}</TableCell>
      <TableCell>{row.mapSettings?.reportTypes.map(e => findReportTypeById(e)).join(', ')}</TableCell>
      <TableCell>{row.mapSettings?.mapProvider === 'openlayers' ? 'Open Layers' : 'Google'}</TableCell>
      <TableCell>
        {row.mapSettings?.showStreetView && row.mapSettings?.streetViewYears.length !== 0
          ? `DA: ${row.mapSettings.streetViewYears}`
          : 'NU'}
      </TableCell>
      <TableCell align="center">{row.mapSettings?.showGroundOverlays ? 'DA' : 'NU'}</TableCell>
      <TableCell align="center">{row.mapSettings?.backgroundType}</TableCell>
      <TableCell>
        {renderTooltipVisibleFields(row.tooltipVisibleFields).map(e => (
          <Typography key={e.group}>
            <span className={classes.reportType}>{e.group}</span>: {e.values.join(', ')}
          </Typography>
        ))}
      </TableCell>
      <TableCell align="center">
        <DeleteIcon className={classes.deleteIcon} onClick={() => deletePage(row.id!)} />
      </TableCell>
    </TableRow>
  );
};

export default PublicPageListRow;
