import { Accordion, AccordionDetails, AccordionSummary, ExpandMoreIcon, makeStyles, Typography } from '@sgde/core';
import { DateTime } from 'luxon';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Dropzone, { FileWithPath } from 'react-dropzone';
import { GalleryItem, GalleryItemWithDate, GalleryPerYearAndMonth } from '../../models/gallery-item.model';
import { Gallery } from '../../models/gallery.model';
import { getResource } from '../../store/Fetch';
import { GIS_CONFIG } from '../../store/settings/Local';
import Dialog from '../Modal/Dialog';
import MultiYearGalleryCarousel from './MultiYearGalleryCarousel';

const useStyles = makeStyles()(() => ({
  img: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

type Props = {
  galleryId: number;
  name: string;
  readonly: boolean;
  onGalleryChanged: (gallery: Gallery) => void;
};

const MultiYearGallery = ({ readonly, galleryId, onGalleryChanged, name }: Props) => {
  const [filesToUpload, setFilesToUpload] = useState<FileWithPath[]>([]);
  const [gallery, setGallery] = useState<GalleryPerYearAndMonth>({});
  const [itemsWithoutDate, setItemsWithoutDate] = useState<GalleryItem[]>([]);
  const [showGalleryDialog, setShowGalleryDialog] = useState(false);
  const [showGalleryItems, setShowGalleryItems] = useState<GalleryItem[]>([]);

  const { classes } = useStyles();

  const files = filesToUpload.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const onDrop = (files: File[] | FileList | null) => {
    if (!files) return;
    const newFilesToUpload = [...filesToUpload, ...Array.from(files)];
    setFilesToUpload(newFilesToUpload);
    onGalleryChanged?.({
      files: newFilesToUpload,
      galleryId,
    });
  };

  const getGallery = async () => {
    const galleryItemsWithDate: GalleryItemWithDate[] = await getResource(
      `${GIS_CONFIG.BASE_API_URL}/gallery/${galleryId}`
    );
    const newItemsWithoutDate: GalleryItem[] = [];
    const newGallery: GalleryPerYearAndMonth = {};
    for (const galleryItemWithDate of galleryItemsWithDate) {
      if (!galleryItemWithDate.date) {
        newItemsWithoutDate.push(galleryItemWithDate.galleryItem);
        continue;
      }

      const itemDate: DateTime = DateTime.fromISO(galleryItemWithDate.date);
      const yearAsString = itemDate?.year?.toString();
      if (!newGallery[yearAsString]) {
        newGallery[yearAsString] = {};
      }

      const monthAsString = itemDate?.month?.toString();
      if (!newGallery[yearAsString][monthAsString]) {
        newGallery[yearAsString][monthAsString] = [];
      }

      newGallery[itemDate.year][itemDate.month].push(galleryItemWithDate.galleryItem);
    }

    setGallery(Object.assign({}, newGallery));
    setItemsWithoutDate(newItemsWithoutDate);
  };

  useEffect(() => {
    if (galleryId) {
      getGallery();
    }

    setTimeout(
      () =>
        onGalleryChanged?.({
          files: [],
          galleryId,
        }),
      0
    );
  }, [galleryId]);

  const onImageClick = (galleryItems: GalleryItem[]) => {
    setShowGalleryItems(galleryItems);
    setShowGalleryDialog(true);
  };

  const renderGallery = useCallback(() => {
    const galleryRows: JSX.Element[] = [];
    for (const year in gallery) {
      for (const month in gallery[year]) {
        const galleryItems = gallery[year][month];
        const galleryRow = (
          <Accordion key={`${year}${month}`}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {DateTime.fromObject({ year: Number(year), month: Number(month) }).toFormat('MMMM y')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {galleryItems.map(gi => (
                  <img
                    className={classes.img}
                    onClick={() => onImageClick(galleryItems)}
                    width="100"
                    key={gi.id}
                    src={`${GIS_CONFIG.BASE_API_DOMAIN}/${gi.fileLink}`}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        );

        galleryRows.push(galleryRow);
      }
    }

    if (itemsWithoutDate.length > 0) {
      galleryRows.push(
        <Accordion key="withoutDate">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Poze fara data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {itemsWithoutDate.map(gi => (
                <img
                  className={classes.img}
                  onClick={() => onImageClick(itemsWithoutDate)}
                  width="100"
                  key={gi.id}
                  src={`${GIS_CONFIG.BASE_API_DOMAIN}/${gi.fileLink}`}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      );
    }

    return <div>{galleryRows}</div>;
  }, [gallery, itemsWithoutDate]);

  return (
    <div style={{ width: '100%' }}>
      <h4>{name}</h4>
      {renderGallery()}
      {!readonly && (
        <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} accept={{ 'image/*': [] }}>
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div
                style={{
                  height: '200px',
                  borderColor: '#00000052',
                  borderStyle: 'dashed',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  marginTop: '10px',
                }}
                {...getRootProps({ className: 'dropzone' })}
              >
                <input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onDrop(event.currentTarget.files)}
                  {...getInputProps()}
                />
                <p style={{ textAlign: 'center' }}>Trageti fisiere aici sau dati click pentru a adauga poza</p>
              </div>
              <aside>
                <h5>Poze ce vor fi incarcate:</h5>
                <ul>{files}</ul>
              </aside>
            </section>
          )}
        </Dropzone>
      )}
      <Dialog maxWidth="md" onClose={() => setShowGalleryDialog(false)} open={showGalleryDialog}>
        <MultiYearGalleryCarousel items={showGalleryItems} galleryName={name}></MultiYearGalleryCarousel>
      </Dialog>
    </div>
  );
};

export default MultiYearGallery;
