import { Box, Link, Typography } from '@sgde/core';
import { GIS_CONFIG } from '../../store/settings/Local';
import { SettingsState } from '../../store/slices/settingsSlice';
import useStore from '../../store/useStore';

export const Logo = () => {
  const settings = useStore((state: SettingsState) => state.settings);

  if (settings.isPublic) {
    return (
      <Link component={'a'} href="https://sgde.ro" target="_blank" rel="noreferrer">
        <Box component="img" alt="SGDE Logo" width={80} src="/logo.png" />
      </Link>
    );
  } else {
    return (
      <Typography variant="h6" className="menu-title">
        {GIS_CONFIG.MENU_TITLE}
      </Typography>
    );
  }
};
