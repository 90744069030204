import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@sgde/core';
import { useState } from 'react';
import PublicPageCreate from './PublicPageCreate';
import PublicPageList from './PublicPageList';

const PublicPage = () => {
  const [value, setValue] = useState('1');

  const handleTabChange = (_: unknown, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="Setari Pagina Public"
      >
        <Tab label="Genereaza pagina" value="1" />
        <Tab label="Pagini existente" value="2" />
      </TabList>
      <TabPanel value="1">
        <PublicPageCreate />
      </TabPanel>
      <TabPanel value="2">
        <PublicPageList />
      </TabPanel>
    </TabContext>
  );
};

export default PublicPage;
