import { Box, groupBy } from '@sgde/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ReportFieldRenderType } from '../../../models/ReportFieldRenderType';
import { DayForecast } from '../../../models/day-forecast.model';
import { DocumentLibrary } from '../../../models/document-library.model';
import { FormReportFieldDefinition } from '../../../models/form-report-field-definition.model';
import { Gallery } from '../../../models/gallery.model';
import { Inventory } from '../../../models/inventory.model';
import { ReportFieldGroup } from '../../../models/report-field-group.model';
import { ReportField } from '../../../models/report-field.model';
import { Report } from '../../../models/report.model';
import { ModalContext } from '../../../primitives/Modal/ModalContext';
import {
  useMultipleFieldDefinitions,
  useReportFields,
  useReportFieldsDefinitions,
} from '../../../store/slices/reportFieldsDefinitionApi';
import { DynamicField } from './DynamicField.tsx';
import { useStyles } from './DynamicForm.styles';
import DynamicFormGroup from './DynamicFormGroup';

// TODO: Add flag in DB
const HIDDEN_FIELD_ID_LIST = [36, 39];

const IMOBIL_MULTI_EDIT_FIELDS = [28, 47, 48, 49, 73, 74, 75, 76, 77, 65, 66, 67, 68, 69, 70, 71];
const CLADIRE_MULTI_EDIT_FIELDS = [41, 20, 21, 22, 23, 24, 25, 26, 30, 31, 32, 33, 72];
const PATRIMONIU_MULTI_EDIT_FIELDS = [46, 43, 54, 55];
const CONDUCTA_MULTI_EDIT_FIELDS = [78, 79, 80, 81, 82];

const MULTI_EDIT_FIELDS = [
  ...IMOBIL_MULTI_EDIT_FIELDS,
  ...CLADIRE_MULTI_EDIT_FIELDS,
  ...PATRIMONIU_MULTI_EDIT_FIELDS,
  ...CONDUCTA_MULTI_EDIT_FIELDS,
];

type Props = {
  reports?: Report[];
  reportTypeId: number;
  readonly?: boolean;
  onValueChanged?: (reportFields: ReportField[]) => void;
  onValidityChange?: (validity: boolean) => void;
  onGalleryChanged?: (fieldId: number, gallery: Gallery) => void;
  onDocumentsChange?: (documentLibraryId: number, documents: DocumentLibrary) => void;
  onMultiYearChange?: (inventory: Inventory) => void;
  forecast?: DayForecast[];
};

const DynamicForm = ({
  reports,
  reportTypeId,
  readonly,
  onValueChanged,
  onValidityChange,
  onGalleryChanged,
  onDocumentsChange,
  onMultiYearChange,
  forecast,
}: Props) => {
  const { classes } = useStyles();
  const [_, setReportFieldDefinitions] = useState<FormReportFieldDefinition[]>([]);
  const [reportFieldGroups, setReportFieldGroups] = useState<ReportFieldGroup[]>([]);
  const [allFieldDefinitions, setAllFieldDefinitions] = useState<FormReportFieldDefinition[]>([]);

  const { data: resources } = useReportFieldsDefinitions({ reportTypeId });
  const { data: reportFields } = useReportFields({ reportId: reports?.[0]?.id ?? -1 }, { skip: !reports?.[0]?.id });
  const multipleFieldDefinitionIds = useMemo(
    () =>
      resources?.reportFieldDefinitions
        .filter(field => field.reportFieldType.name === 'Multiple' || field.reportFieldType.name === 'MultipleGroup')
        .map(field => field.id) || [],
    [resources]
  );
  const { data: multipleFieldDefinitions } = useMultipleFieldDefinitions(
    { multipleFieldDefinitionIds },
    { skip: !multipleFieldDefinitionIds?.length }
  );

  const { dispatch: modalDispatch } = useContext(ModalContext);

  useEffect(() => {
    if (
      resources &&
      (!reports?.[0]?.id || reportFields) &&
      (!multipleFieldDefinitionIds?.length || multipleFieldDefinitions)
    )
      fetchReportDefinition();
  }, [resources, reportFields, reports, multipleFieldDefinitions, multipleFieldDefinitionIds]);

  useEffect(() => {
    modalDispatch?.({
      title: `Creaza raport ${resources?.name}`,
      type: 'update',
    });
  }, [resources?.name]);

  const fetchReportDefinition = async () => {
    if (resources) {
      const isMultiEdit = (reports?.length ?? 0) > 1;
      const filteredReportFieldDefinitions = resources.reportFieldDefinitions.reduce(
        (result: FormReportFieldDefinition[], field) => {
          if (
            !HIDDEN_FIELD_ID_LIST.includes(field.id) &&
            (!isMultiEdit || MULTI_EDIT_FIELDS.includes(field.id)) &&
            !multipleFieldDefinitions?.some(multipleField => multipleField.childReportFieldDefinitionId === field.id)
          ) {
            const f = !isMultiEdit ? reportFields?.find(f => f.reportFieldDefinitionId === field.id) : undefined;
            result.push({
              ...field,
              value: f?.value,
              multipleId: f?.multipleValueId,
            });
          }
          return result;
        },
        []
      );

      setAllFieldDefinitions(resources.reportFieldDefinitions);
      setReportFieldDefinitions(filteredReportFieldDefinitions);

      const filteredGroupFields = resources.reportFieldGroups.map(group => {
        const filteredFields = filteredReportFieldDefinitions.filter(field => field.reportFieldGroupId === group.id);
        return {
          ...group,
          fields: filteredFields,
        };
      });

      setReportFieldGroups(filteredGroupFields);
      !readonly && onValidityChange?.(!filteredReportFieldDefinitions?.some(field => field.error));
    }
  };

  const onValueChangedEvent = (changedField: FormReportFieldDefinition): void => {
    setReportFieldDefinitions(reportFieldDefinitions => {
      const updatedRfd = reportFieldDefinitions.reduce((result: ReportField[], field) => {
        const newField = field.id === changedField.id ? changedField : field;
        if (newField.value || field.id === changedField.id) {
          result.push({
            reportFieldDefinitionId: newField.id,
            value: newField.value,
          } as ReportField);
        }

        return result;
      }, []);
      onValueChanged?.(updatedRfd);
      const rfds = reportFieldDefinitions.map(rfd => (rfd.id === changedField.id ? changedField : rfd));

      const filteredGroupFields = resources?.reportFieldGroups.map(group => {
        const filteredFields = rfds.filter(field => field.reportFieldGroupId === group.id);
        return {
          ...group,
          fields: filteredFields,
        };
      });

      setReportFieldGroups(filteredGroupFields ?? []);
      return rfds;
    });
  };

  return (
    <form>
      {reportFieldGroups?.map(
        fieldGroup =>
          !!fieldGroup.fields.length && (
            <div key={`formGroup-${fieldGroup.id}`} className={classes.formGroup}>
              <h4>{fieldGroup.name}</h4>
              {Object.values(groupBy(fieldGroup.fields, 'order')).map(groupElements => {
                const formGroup = groupElements.find(rfd => rfd.groupTitle);

                return !formGroup ? (
                  <Box key={`regular-field-${groupElements[0].order}`} component="div" className={classes.formItem}>
                    {groupElements.map((field: FormReportFieldDefinition) => (
                      <DynamicField
                        key={`dynamic-form-item-${field.id}`}
                        readonly={field.renderType === ReportFieldRenderType.Readonly || readonly}
                        field={field}
                        allFields={allFieldDefinitions}
                        allGroups={resources?.reportFieldGroups || []}
                        multiFields={multipleFieldDefinitions}
                        reportFields={reportFields}
                        onChange={onValueChangedEvent}
                        onDocumentsChange={onDocumentsChange}
                        onGalleryChanged={onGalleryChanged}
                        onMultiYearChange={onMultiYearChange}
                        forecast={forecast}
                      />
                    ))}
                  </Box>
                ) : (
                  <DynamicFormGroup
                    key={`accordion-group-field-${formGroup.groupTitle}`}
                    reportFields={reportFields}
                    groupElements={groupElements}
                    allFields={allFieldDefinitions}
                    allGroups={resources?.reportFieldGroups || []}
                    multiFields={multipleFieldDefinitions}
                    formGroup={formGroup}
                    readonly={readonly}
                    onValueChanged={onValueChangedEvent}
                    onDocumentsChange={onDocumentsChange}
                    onGalleryChanged={onGalleryChanged}
                    onMultiYearChange={onMultiYearChange}
                  />
                );
              })}
            </div>
          )
      )}
    </form>
  );
};

export default DynamicForm;
