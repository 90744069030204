import { Accordion, AccordionDetails, AccordionSummary, Box, ExpandMoreIcon, Typography } from '@sgde/core';
import { ReportFieldRenderType } from '../../../models/ReportFieldRenderType';
import { DocumentLibrary } from '../../../models/document-library.model';
import { FormReportFieldDefinition } from '../../../models/form-report-field-definition.model';
import { Gallery } from '../../../models/gallery.model';
import { Inventory } from '../../../models/inventory.model';
import { MultipleFieldDefinition } from '../../../models/multiple-field-definition';
import { ReportFieldGroup } from '../../../models/report-field-group.model';
import { ReportField } from '../../../models/report-field.model';
import { DynamicField } from './DynamicField.tsx';
import { useStyles } from './DynamicFormGroup.styles';

type IDynamicFormGroupProps = {
  reportFields?: ReportField[];
  allFields: FormReportFieldDefinition[];
  allGroups: ReportFieldGroup[];
  multiFields?: MultipleFieldDefinition[];
  groupElements: FormReportFieldDefinition[];
  formGroup: FormReportFieldDefinition;
  readonly?: boolean;
  onValueChanged?: (reportField: FormReportFieldDefinition) => void;
  onGalleryChanged?: (fieldId: number, gallery: Gallery) => void;
  onDocumentsChange?: (documentLibraryId: number, documents: DocumentLibrary) => void;
  onMultiYearChange?: (inventory: Inventory) => void;
};

const DynamicFormGroup = ({
  reportFields = [],
  groupElements,
  allFields,
  allGroups,
  formGroup,
  readonly,
  multiFields,
  onValueChanged,
  onGalleryChanged,
  onDocumentsChange,
  onMultiYearChange,
}: IDynamicFormGroupProps) => {
  const { classes } = useStyles();

  const isAnyFieldWithValue = (fields: FormReportFieldDefinition[]): boolean =>
    fields.some(
      field =>
        field.reportFieldType.name === 'String' &&
        !!reportFields.find(rf => rf.reportFieldDefinitionId === field.id)?.value?.trim()
    );

  return (
    <Accordion
      key={`accordion-group-field-${formGroup.groupTitle}`}
      className={classes.accordionContainer}
      defaultExpanded={isAnyFieldWithValue(groupElements)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{formGroup.groupTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.accordionDetails}>
          {groupElements.map((field: FormReportFieldDefinition) => {
            return (
              <Box
                key={`accordion-element-${field.id}`}
                className={field.reportFieldType.name === 'Multiple' ? '' : classes.accordionField}
              >
                <DynamicField
                  key={`dynamic-form-groupped-item-${field.id}`}
                  readonly={field.renderType === ReportFieldRenderType.Readonly || readonly}
                  field={field}
                  allFields={allFields}
                  allGroups={allGroups}
                  multiFields={multiFields}
                  reportFields={reportFields}
                  onChange={field => onValueChanged?.(field)}
                  onDocumentsChange={onDocumentsChange}
                  onGalleryChanged={onGalleryChanged}
                  onMultiYearChange={onMultiYearChange}
                />
              </Box>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DynamicFormGroup;
