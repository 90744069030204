import { AddIcon, IconButton, RemoveIcon } from '@sgde/core';
import { useEffect, useState } from 'react';
import { MultipleItemDto } from '../../../models/DTO/multiple/multipleItemDTO';
import { DayForecast } from '../../../models/day-forecast.model';
import { DocumentLibrary } from '../../../models/document-library.model';
import { FormReportFieldDefinition } from '../../../models/form-report-field-definition.model';
import { Gallery } from '../../../models/gallery.model';
import { Inventory } from '../../../models/inventory.model';
import { MultipleFieldDefinition } from '../../../models/multiple-field-definition';
import { ReportFieldGroup } from '../../../models/report-field-group.model';
import { useMultipleItems } from '../../../store/slices/reportFieldsDefinitionApi';
import { useStyles } from './Multiple.styles.tsx';
import { MultipleLine } from './MultipleLine.tsx';

interface IProps {
  parentField: FormReportFieldDefinition;
  allFields: FormReportFieldDefinition[];
  allGroups: ReportFieldGroup[];
  multiFields?: MultipleFieldDefinition[];
  readonly?: boolean;
  onChange?: (fieldDefinition: FormReportFieldDefinition) => void;
  onGalleryChanged?: (fieldId: number, gallery: Gallery) => void;
  onDocumentsChange?: (documentLibraryId: number, documents: DocumentLibrary) => void;
  onMultiYearChange?: (inventory: Inventory) => void;
  forecast?: DayForecast[];
}

export const Multiple = ({
  parentField,
  allFields,
  allGroups,
  multiFields,
  readonly,
  onChange,
  onDocumentsChange,
  onMultiYearChange,
  onGalleryChanged,
  forecast,
}: IProps) => {
  const { classes } = useStyles();
  const [fieldRowIndex, setFieldRowIndex] = useState<{ order: number }[]>([{ order: 0 }]);
  const [values, setValues] = useState<Partial<MultipleItemDto>[]>([]);
  const { data: multiItems } = useMultipleItems(
    { multipleId: parentField.multipleId! },
    { skip: !parentField.multipleId || !!parentField.value }
  );

  useEffect(() => {
    if (parentField.value && typeof parentField.value === 'string') {
      const multiItems = JSON.parse(parentField.value) as MultipleItemDto[];
      setValues(multiItems);
      setFieldRowIndex([...new Set(multiItems.map(i => i.key))].map(key => ({ order: Number(key) })));
    } else if (multiItems && parentField.multipleId) {
      setValues(multiItems);
      setFieldRowIndex([...new Set(multiItems.map(i => i.key))].map(key => ({ order: Number(key) })));
      onChange?.({ ...parentField, value: JSON.stringify(multiItems) });
    } else {
      setValues([]);
      setFieldRowIndex([{ order: 0 }]);
    }
  }, [multiItems, parentField.value]);

  const multipleChildFields = allFields.filter(fieldDefinition =>
    multiFields?.some(
      multiField =>
        multiField.parentReportFieldDefinitionId === parentField.id &&
        fieldDefinition.id === multiField.childReportFieldDefinitionId
    )
  );

  const addRow = () => setFieldRowIndex([...fieldRowIndex, { order: fieldRowIndex.at(-1)!.order + 1 }]);
  const removeRow = (order: number) => {
    const filteredRows = fieldRowIndex.filter(field => field.order !== order);
    setFieldRowIndex(filteredRows);
    const newValues = values.filter(v => v.key !== order.toString());
    setValues(newValues);
    onChange?.({ ...parentField, value: JSON.stringify(newValues) });
  };

  const handleChange = (order: number, fieldDefinition: FormReportFieldDefinition) => {
    const multipleItem = {
      key: order.toString(),
      value: fieldDefinition.value,
      multipleId: parentField.multipleId,
      multipleFieldsDefinitionId: multiFields?.find(
        multiField => multiField.childReportFieldDefinitionId === fieldDefinition.id
      )?.id,
    } as Partial<MultipleItemDto>;
    const newValues = values.some(
      f => f.multipleFieldsDefinitionId === multipleItem.multipleFieldsDefinitionId && f.key === multipleItem.key
    )
      ? values.reduce((result, current) => {
          if (
            current.multipleFieldsDefinitionId === multipleItem.multipleFieldsDefinitionId &&
            current.key === multipleItem.key
          ) {
            result.push({ ...current, value: multipleItem.value });
          } else {
            result.push(current);
          }
          return result;
        }, [] as Partial<MultipleItemDto>[])
      : [...values, multipleItem];
    setValues(newValues);
    onChange?.({ ...parentField, value: JSON.stringify(newValues) });
  };

  const getWeekdayName = (date: Date) => {
    const weekdays = ['Duminica', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'];
    return weekdays[date.getDay()];
  };

  return (
    <div className={classes.multiple}>
      {parentField.id === 120 && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {forecast?.map(f => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{ fontWeight: 500 }}>{getWeekdayName(new Date(f.date))}</p>
              <p>{Math.trunc(f.temperature)} &deg;C</p>
              <img src={f.iconUrl} />
            </div>
          ))}
        </div>
      )}
      {fieldRowIndex.map(row => (
        <div className={classes.multipleLine} key={row.order}>
          <MultipleLine
            key={`multiple-line-item-${row.order}`}
            readonly={readonly}
            childMultipleFields={multipleChildFields.map(field => ({
              ...field,
              value: values?.find(
                x =>
                  x.key === row.order.toString() &&
                  x.multipleFieldsDefinitionId ===
                    multiFields?.find(y => y.childReportFieldDefinitionId === field.id)?.id
              )?.value,
            }))}
            allGroups={allGroups}
            onChange={field => handleChange(row.order, field)}
            onDocumentsChange={onDocumentsChange}
            onGalleryChanged={onGalleryChanged}
            onMultiYearChange={onMultiYearChange}
          />

          <IconButton
            disabled={readonly}
            onClick={() => {
              row.order === 0 ? addRow() : removeRow(row.order);
            }}
          >
            {row.order === 0 ? <AddIcon /> : <RemoveIcon />}
          </IconButton>
        </div>
      ))}
    </div>
  );
};
