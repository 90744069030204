import {
  Button,
  DeleteIcon,
  DialogActions,
  DialogContent,
  DialogTitle,
  EditIcon,
  EditLocationIcon,
  Grid,
  IconButton,
  LinkOffIcon,
  TextField,
  Tooltip,
  useSnackbar,
} from '@sgde/core';
import { Polygon } from 'ol/geom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReportTypes } from '../../models/ReportType';
import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import ConfirmationButton from '../../primitives/Button/ConfirmationButton';
import { DataContext } from '../../primitives/Data/DataContext';
import Dialog from '../../primitives/Modal/Dialog';
import { ModalContext } from '../../primitives/Modal/ModalContext';
import { useAddMeasurement, useAddMeasurementFields, useDeleteMeasurements } from '../../store/slices/measurementsApi';
import { useDeleteReportFields, useReportsWithParent } from '../../store/slices/reportsApi';
import { useGet5DayForecast } from '../../store/slices/weatherApi';
import useStore from '../../store/useStore';
import DynamicForm from '../Form/DynamicForm/DynamicForm';
import EditDialog from './EditDialog';
import ReportLink from './ReportLink';

type Props = {
  map: OpenLayersMap;
  reportIds: number[];
  areaMeasurementId: number;
  feature: SGDEFeature;
  closeDialog: () => void;
};

const ReportView = ({ map, reportIds, areaMeasurementId, feature, closeDialog }: Props) => {
  const reportId = reportIds?.length === 1 && reportIds[0];
  const { data: reports } = useReportsWithParent(reportIds);
  const reportTypeId = reports?.[0]?.reportTypeId; // TODO: Send this via props
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const { isEdited, setEdit, saveEdit } = useContext(DataContext);
  const [isModifying, setIsModifying] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setEditingAreaMesurement = useStore(state => state.setEditing);
  const [showReportLink, setShowReportLink] = useState<boolean>(false);
  const [deleteReportFields, { isSuccess: isReportFieldsDeleted, error: reportFieldsDeleteError }] =
    useDeleteReportFields();
  const [addMeasurement, { isSuccess: isMeasurementAdded }] = useAddMeasurement();
  const [addMeasurementFields, { isSuccess: isMeasurementFieldsAdded }] = useAddMeasurementFields();
  const [deleteMeasurements, { isSuccess: isMeasurementDeleted }] = useDeleteMeasurements();

  const [get5DayForecast, { data: forecast }] = useGet5DayForecast();

  useEffect(() => {
    if (isMeasurementDeleted) {
      closeDialog();
      openSnackbar({ severity: 'success', message: 'Masuratoare stearsa' });
    }
    if (isReportFieldsDeleted) {
      closeDialog();
      feature.reportId = undefined;
      openSnackbar({ severity: 'success', message: 'Masuratoare dezlegata' });
    }

    if (reportFieldsDeleteError) {
      console.error(reportFieldsDeleteError);
      openSnackbar({ severity: 'error', message: 'Eroare la dezlegarea masuratorii' });
    }

    if (isMeasurementAdded && isMeasurementFieldsAdded) {
      closeDialog();
      openSnackbar({ severity: 'success', message: 'Masuratoare actualizata' });
    }
  }, [
    isMeasurementDeleted,
    isReportFieldsDeleted,
    reportFieldsDeleteError,
    isMeasurementAdded,
    isMeasurementFieldsAdded,
    map,
  ]);

  useEffect(() => {
    const polygon = feature.getGeometry() as Polygon;
    const transformed = polygon.clone().transform('EPSG:3844', 'EPSG:4326') as Polygon;
    const firstCoordinate = transformed.getCoordinates().at(0)?.at(0);

    if (!firstCoordinate) {
      return;
    }

    get5DayForecast({
      lat: firstCoordinate[1],
      lng: firstCoordinate[0],
    });
  }, [feature]);

  const onEditMeasurement = useCallback(() => {
    map.editFeature(feature);
    setEdit?.(feature);
    setEditingAreaMesurement(true);
    closeDialog();
  }, [map, feature, setEdit, setEditingAreaMesurement, closeDialog]);

  const onFinishEditMeasurement = useCallback(async () => {
    const editedFeature = saveEdit?.();
    if (!editedFeature || !editedFeature.reportId) return;
    map.endEditFeature();
    modalDispatch?.({ type: 'close' });

    const id = editedFeature.reportId;
    const areaMeasurementId = editedFeature.areaMeasurementId;
    const measurement = editedFeature.getAreaMeasurement();
    const fields = [
      {
        reportFieldDefinitionId: 36,
        value: String(true),
      },
      {
        reportFieldDefinitionId: 1,
        value: String(measurement.area),
      },
    ];

    addMeasurement({ id: areaMeasurementId!, measurement });
    addMeasurementFields({ id, fields });
    map.reloadMeasurementsLayer(feature.reportTypeId as ReportTypes);
  }, [saveEdit, modalDispatch, addMeasurement, addMeasurementFields]);

  const onDeleteMeasurement = () => {
    deleteMeasurements([areaMeasurementId]);
    map.reloadMeasurementsLayer(feature.reportTypeId as ReportTypes);
  };

  return reports?.length ? (
    isModifying ? (
      <EditDialog reports={reports.map(r => ({ ...r }))} closeDialog={closeDialog} map={map} />
    ) : (
      <>
        <DialogTitle>Vizualizare raport</DialogTitle>
        <DialogContent>
          <Grid container>
            {reportTypeId === 2 && (
              <>
                <Grid item xs={11}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Raport parinte"
                    value={reports[0].reportParent?.name}
                    placeholder="Setati un raport parinte"
                  />
                </Grid>
                <Grid item display="flex" flexDirection="row" justifyContent=" center" xs={1}>
                  <Tooltip title="Editare parinte" placement="top">
                    <IconButton onClick={() => setShowReportLink(true)} color="primary" size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Dialog onClose={() => setShowReportLink(false)} open={showReportLink}>
                  <ReportLink reportType={1} report={reports[0]} closeDialog={closeDialog} />
                </Dialog>
              </>
            )}
            <Grid item xs={12}>
              <TextField fullWidth value={reports[0].name} disabled variant="outlined" margin="normal" label="Nume" />
              <DynamicForm forecast={forecast} reports={reports} reportTypeId={reportTypeId ?? 0} readonly />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ConfirmationButton
            color="error"
            startIcon={<DeleteIcon />}
            onConfirm={() => onDeleteMeasurement()}
            message="Sunteti sigur ca doriti sa stergeti masuratoarea?"
            title="Confirmare stergere"
          >
            Sterge
          </ConfirmationButton>
          {isEdited?.(feature) ? (
            <Button startIcon={<EditLocationIcon />} onClick={onFinishEditMeasurement} color="primary">
              Salveaza
            </Button>
          ) : (
            <Button startIcon={<EditLocationIcon />} onClick={onEditMeasurement} color="secondary">
              Editeaza
            </Button>
          )}
          <Button startIcon={<EditIcon />} onClick={() => setIsModifying(true)} color="secondary">
            Modifica
          </Button>
          <ConfirmationButton
            startIcon={<LinkOffIcon />}
            onConfirm={() => deleteReportFields([reportId as number])}
            color="error"
            title="Confirmare dezlegare"
            message={`Sunteti sigur ca vreti sa dezlegati fisa "${reports?.[0]?.name}" de masuratoare?`}
          >
            Dezleaga
          </ConfirmationButton>
        </DialogActions>
      </>
    )
  ) : (
    <></>
  );
};

export default ReportView;
