import { DataGrid, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import {
  Box,
  Button,
  debounce,
  FormControl,
  FormControlLabel,
  FormLabel,
  GetAppIcon,
  Radio,
  RadioGroup,
  Stack,
} from '@sgde/core';
import csvDownload from 'json-to-csv-export';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Report } from '../../models/report.model';
import {
  useDownloadReport,
  useDownloadReport1Blob,
  useDownloadReport2Blob,
  useGenerateReport1,
  useGenerateReport2,
} from '../../store/slices/generateReportsApi';

import { ModalContext } from '../Modal/ModalContext';
import { ProgressContext } from '../Progress/Progress';

const ExportReports = () => {
  const [generateReport1, { data: report1 }] = useGenerateReport1();
  const [generateReport2, { data: report2 }] = useGenerateReport2();
  const [downloadReport, { data: downloadedReport }] = useDownloadReport();
  const [downloadReport1Blob, { data: downloadedReport1Blob }] = useDownloadReport1Blob();
  const [downloadReport2Blob, { data: downloadedReport2Blob }] = useDownloadReport2Blob();

  const { dispatch: modal } = useContext(ModalContext);

  const report1Columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80, filterable: false },
    {
      field: 'numarCasa',
      headerName: 'Nr Casa',
      width: 100,
      editable: false,
    },
    {
      field: 'cf',
      headerName: 'CF',
      width: 100,
      editable: false,
    },
    {
      field: 'nrCad',
      headerName: 'Nr cad',
      width: 200,
      editable: false,
    },
    {
      field: 'nrTop',
      headerName: 'Nr top',
      width: 200,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Imobil',
      width: 350,
      editable: false,
    },
    {
      field: 'adresa',
      headerName: 'Adresa',
      width: 350,
      editable: true,
    },
    {
      field: 'denumireCladire',
      headerName: 'Cladire',
      width: 200,
      editable: false,
    },
    {
      field: 'regimInaltime',
      headerName: 'Reg. H',
      width: 120,
      editable: false,
    },
    {
      field: 'scMasurata',
      headerName: 'S Masurata',
      width: 120,
      editable: false,
    },
    {
      field: 'scDeclarata',
      headerName: 'S Declarata',
      width: 120,
      editable: false,
    },
  ];

  const report2Columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80, filterable: false },
    {
      field: 'name',
      headerName: 'Patrimoniu',
      width: 350,
      editable: false,
    },
    {
      field: 'cf',
      headerName: 'CF',
      width: 100,
      editable: false,
    },
    {
      field: 'cadTop',
      headerName: 'CadTop',
      width: 150,
      editable: false,
    },
    {
      field: 'nrInventar',
      headerName: 'Nr Inventar',
      width: 150,
      editable: false,
    },
    {
      field: 'patrimoniuPrivat',
      headerName: 'Tip Patrimoniu',
      width: 120,
      editable: false,
    },
    {
      field: 'valoareInventar',
      headerName: 'Val',
      width: 120,
      editable: false,
    },
    {
      field: 'adresa',
      headerName: 'Adresa',
      width: 350,
      editable: true,
    },
    {
      field: 'numar',
      headerName: 'Numar',
      width: 120,
      editable: false,
    },
  ];

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [currentReport, setCurrentReport] = useState<string>();
  const [searchTerm, _setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentFilter, setCurrentFilter] = useState<any>();
  const [_currentSort, setCurrentSort] = useState<any>();

  const { dispatch: progress } = useContext(ProgressContext);

  const downloadReportClick = async () => {
    if (currentReport === 'report1') {
      downloadReport({
        searchReportTypeId: 1,
        pageSize: currentPageSize,
        page: currentPage,
        searchTerm,
        filterModel: currentFilter,
        sortModel: _currentSort,
      });
    }

    if (currentReport === 'report2') {
      downloadReport({
        searchReportTypeId: 4,
        pageSize: currentPageSize,
        page: currentPage,
        searchTerm,
        filterModel: currentFilter,
        sortModel: _currentSort,
      });
    }
  };

  const searchReports = async () => {
    if (!currentReport) {
      return;
    }

    progress?.('show');
    if (currentReport === 'report1') {
      generateReport1({
        searchReportTypeId: 1,
        pageSize: currentPageSize,
        page: currentPage,
        searchTerm,
        filterModel: currentFilter,
      });
    }

    if (currentReport === 'report2') {
      generateReport2({
        searchReportTypeId: 4,
        pageSize: currentPageSize,
        page: currentPage,
        searchTerm,
        filterModel: currentFilter,
      });
    }
  };

  useEffect(() => {
    modal?.({
      type: 'update',
      actions: [
        {
          content: (
            <Stack spacing={2} direction={'row'} sx={{ width: '100%' }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<GetAppIcon />}
                onClick={() => downloadReport1Blob()}
              >
                Exporta Raport Tip 1
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<GetAppIcon />}
                onClick={() => downloadReport2Blob()}
              >
                Exporta Raport Tip 2: Patrimoniu
              </Button>
            </Stack>
          ),
        },
      ],
    });

    return () => {
      modal?.({
        type: 'update',
        actions: [],
      });
    };
  }, [downloadReport1Blob, downloadReport2Blob, modal]);

  useEffect(() => {
    if (report1) {
      setReports(report1.items);
      setTotalItems(report1.totalItems);
      progress?.('hide');
    }
  }, [report1]);

  useEffect(() => {
    if (report2) {
      setReports(report1.items);
      setTotalItems(report1.totalItems);
      progress?.('hide');
    }
  }, [report2]);

  useEffect(() => {
    console.log('files', downloadedReport1Blob, downloadedReport2Blob);
  }, [downloadedReport1Blob, downloadedReport2Blob]);

  useEffect(() => {
    if (!downloadedReport) {
      return;
    }

    progress?.('show');
    const date = new Date();
    const dataToConvert = {
      data: [],
      filename:
        'Export_' +
        currentReport +
        '_Filtrat_' +
        date.toLocaleDateString('en-GB') +
        '_' +
        date.toLocaleTimeString('it-IT'),
      delimiter: ',',
    };

    dataToConvert.data = downloadedReport;
    csvDownload(dataToConvert);
    progress?.('hide');
  }, [downloadedReport]);

  // @ts-ignore
  const onPaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const onFilterChange = (filterModel: GridFilterModel) => {
    console.log(filterModel);

    // if (filterModel?.items[0]?.value) {
    //     setSearchTerm(filterModel?.items[0]?.value);
    // }

    setCurrentFilter(filterModel);
  };

  const onSortChange = (sortModel: GridSortModel) => {
    console.log(sortModel);

    // if (filterModel?.items[0]?.value) {
    //     setSearchTerm(filterModel?.items[0]?.value);
    // }

    setCurrentSort(sortModel);
  };

  const onReportChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentReport((event.target as HTMLInputElement).value);

    if ((event.target as HTMLInputElement).value === 'report1') {
      setColumns(report1Columns);
    }

    if ((event.target as HTMLInputElement).value === 'report2') {
      setColumns(report2Columns);
    }

    setCurrentPage(0);
  };

  useEffect(() => {
    searchReports();
  }, [searchTerm, currentPage, currentPageSize, columns, currentFilter]);

  const searchTermChanged = (searchTerm: string) => searchTerm;

  const debounceTermChanged = useCallback(debounce(searchTermChanged, 700), []);

  useEffect(() => {
    debounceTermChanged(searchTerm);
  }, [searchTerm]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Alege raportul</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={onReportChange}
          >
            <FormControlLabel value="report1" control={<Radio />} label="Raport 1" />
            <FormControlLabel value="report2" control={<Radio />} label="Raport 2" />
          </RadioGroup>
        </FormControl>
      </div>
      <div style={{ flex: 1 }}>
        {columns.length > 0 ? (
          <Stack sx={{ height: '100%', width: '100%', display: 'flex' }}>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<GetAppIcon />}
                onClick={() => downloadReportClick()}
              >
                Exporta Raport Curent
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <DataGrid
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: currentPageSize,
                    },
                  },
                }}
                rows={reports}
                rowCount={totalItems}
                columns={columns}
                pageSizeOptions={[5, 20, 50, 100]}
                filterMode="server"
                sortingMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={onSortChange}
                disableRowSelectionOnClick
                paginationModel={{ page: currentPage, pageSize: currentPageSize }}
                onPaginationModelChange={paginationModel => {
                  setCurrentPage(paginationModel.page);
                  setCurrentPageSize(paginationModel.pageSize);
                }}
                paginationMode="server"
              />
            </Box>
          </Stack>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default ExportReports;
