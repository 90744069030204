import { CloseIcon, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@sgde/core';
import { ReactNode, createContext, useReducer, useState } from 'react';

type State = {
  dispatch?: (action: DialogAction) => void;

  state: any;
};

const initialState: State = {
  state: {},
};

type Props = {
  children: ReactNode | ReactNode[];
};

export type DialogAction = {
  actions?: { content: ReactNode }[];

  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  mainContent?: ReactNode;

  title?: string;

  fullScreen?: boolean;

  type?: 'open' | 'close' | 'update' | 'actions' | 'setWidth';
};

const ModalContext = createContext<Partial<State>>(initialState);
const { Provider } = ModalContext;
const ModalProvider = ({ children }: Props) => {
  const [maxWidth, setMaxWidth] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl'>('sm');
  const [open, setOpen] = useState(false);
  const [actions, setActions] = useState<{ content: ReactNode }[]>([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [mainContent, setMainContent] = useState<ReactNode>(null);
  const [title, setTitle] = useState<string | undefined>('');

  const [state, dispatch] = useReducer((state: any, action: DialogAction) => {
    switch (action.type) {
      case 'open':
        setOpen(true);
        action.actions && setActions(action.actions);
        setMaxWidth(action.width || 'sm');
        setMainContent(action.mainContent);
        setTitle(action.title);
        setFullScreen(action.fullScreen || false);
        break;
      case 'close':
        setOpen(false);
        setFullScreen(action.fullScreen || false);
        break;
      case 'update':
        action.actions && setActions(action.actions);
        setTitle(action.title);
        break;
      case 'actions':
        action.actions && setActions(action.actions);
        break;
      case 'setWidth':
        setMaxWidth(action.width || 'sm');
        break;
      default:
        return state;
    }
  }, initialState);

  const handleClose = () => setOpen(false);

  const actionsContent = actions && actions.map(action => action?.content);
  return (
    <Provider value={{ state, dispatch }}>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.error.main,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>{mainContent}</DialogContent>
        <DialogActions>{actionsContent}</DialogActions>
      </Dialog>
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
