import { HelpIcon, ListItemIcon, ListItemText, MenuItem } from '@sgde/core';
const SUPPORT_URL = 'https://sgde.ro/support' as const;

export const PublicMenuItems = () => (
  <MenuItem onClick={() => window.open(SUPPORT_URL, '_blank')}>
    <ListItemIcon>
      <HelpIcon fontSize="small" />
    </ListItemIcon>
    <ListItemText>Suport</ListItemText>
  </MenuItem>
);
