import { Box, useCurrentUser } from '@sgde/core';
import OpenLayersMapView from '../../primitives/OpenLayers/OpenLayersMapView';

export const Home = () => {
  const { data: currentUser, isFetching } = useCurrentUser();

  return currentUser && !currentUser?.softDeleted ? (
    <OpenLayersMapView />
  ) : !isFetching ? (
    <Box sx={{ height: '50%', transform: 'translate(0, 50%)' }}>
      Contul dumneavoastra va fi activat in cel mai scurt timp.
      <br />
      Pentru mai multe detalii va rugam sa accesati pagina de support.
    </Box>
  ) : (
    <></>
  );
};
