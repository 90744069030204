import { ListItemIcon, ListItemText, MenuItem } from '@sgde/core';
import { ReactNode, useContext } from 'react';
import { DialogAction, ModalContext } from '../Modal/ModalContext';

type MenuWithDialogType = {
  icon: ReactNode;
  children?: ReactNode;
} & DialogAction;

export const MenuItemWithDialogAction = ({ icon, children, title, ...props }: MenuWithDialogType) => {
  const { dispatch: modal } = useContext(ModalContext);

  function openDialog(data: DialogAction) {
    modal?.(data);
  }

  return (
    <MenuItem
      onClick={() =>
        openDialog({
          ...props,
          type: 'open',
          title: title,
        })
      }
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{children || title}</ListItemText>
    </MenuItem>
  );
};
