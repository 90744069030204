import { Feature } from 'ol';
import { ObjectWithGeometry } from 'ol/Feature';
import { Geometry } from 'ol/geom';
import { CreateAreaMeasurement } from '../measurement.model';
import { CustomStylingField, getAreaMeasurement } from './feature-utils';

enum FeatureType {
  Measurement = 0,
  Streetview = 1,
}

class SGDEFeature extends Feature {
  public set measurementId(value: number) {
    this.set('measurementId', value);
  }

  public get measurementId(): number {
    return this.get('measurementId');
  }

  public set reportTypeId(value: number) {
    this.set('reportTypeId', value);
  }

  public get reportTypeId(): number {
    return this.get('reportTypeId');
  }

  public set areaMeasurementId(value: number) {
    this.setId(value);
  }

  public get areaMeasurementId(): number {
    return this.getId() as number;
  }

  public set reportId(value: number | undefined) {
    this.set('reportId', value);
  }

  public get reportId(): number | undefined {
    return this.get('reportId');
  }

  public set toolTip(value: string | undefined) {
    this.set('tooltip', value);
  }

  public get toolTip(): string | undefined {
    return this.get('tooltip');
  }

  public get customStylesByFields(): CustomStylingField[] {
    return this.get('customStylingFields');
  }

  constructor(feature: Feature) {
    const geometry = feature.getGeometry();
    const properties = feature.getProperties();

    const objectWithGeometry = {
      ...properties,
      geometry,
    } as ObjectWithGeometry<Geometry>;

    super(objectWithGeometry);
    this.setId(feature.getId());
  }

  getAreaMeasurement(): CreateAreaMeasurement {
    return getAreaMeasurement(this);
  }
}

export default SGDEFeature;
export { FeatureType };
