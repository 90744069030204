import { Button, TextField, useCurrentUser, useUpdateUser } from '@sgde/core';
import { useState } from 'react';
import { useUpdatePassword } from '../../store/slices/authenticationApi';
import { useStyles } from './Personal.style.ts';

const Personal = () => {
  const { classes } = useStyles();
  const { data: currentUser } = useCurrentUser();
  const [updateUser] = useUpdateUser();
  const [updatePassword] = useUpdatePassword();
  const [changePassword, setChangePassword] = useState(false);
  const [values, setValues] = useState({
    name: currentUser?.username,
    email: currentUser?.email,
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({ name: false, email: false, password: false, confirmPassword: false });

  return (
    <div className={classes.root}>
      {changePassword ? (
        <>
          <TextField
            label="Parola noua"
            type="password"
            error={errors.password}
            value={values.password}
            helperText="Mimin 6 caractere"
            onChange={({ target: { value } }) => {
              setErrors(errors => ({ ...errors, password: false }));
              setValues(values => ({ ...values, password: value }));
            }}
          />
          <TextField
            label="Confirma parola"
            type="password"
            error={errors.confirmPassword}
            value={values.confirmPassword}
            onChange={({ target: { value } }) => {
              setErrors(errors => ({ ...errors, confirmPassword: false }));
              setValues(values => ({ ...values, confirmPassword: value }));
            }}
          />
        </>
      ) : (
        <>
          <TextField
            label="Nume utilizator"
            type="text"
            error={errors.name}
            value={values.name}
            onChange={({ target: { value } }) => {
              setErrors(errors => ({ ...errors, name: false }));
              setValues(values => ({ ...values, name: value }));
            }}
          />
          <TextField
            label="Adresa de email"
            type="email"
            error={errors.email}
            value={values.email}
            onChange={({ target: { value } }) => {
              setErrors(errors => ({ ...errors, email: false }));
              setValues(values => ({ ...values, email: value }));
            }}
          />
        </>
      )}
      <div>
        <Button
          onClick={() => {
            if (changePassword) {
              if (values.password.length < 6) {
                setErrors(errors => ({ ...errors, password: true }));
                return;
              }
              if (values.confirmPassword !== values.password) {
                setErrors(errors => ({ ...errors, confirmPassword: true }));
                return;
              }
              updatePassword(values.password);
            } else {
              if (!values.name) {
                setErrors(errors => ({ ...errors, name: true }));
                return;
              }
              if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
                setErrors(errors => ({ ...errors, email: true }));
                return;
              }
              updateUser({ username: values.name, email: values.email! });
            }
          }}
        >
          Salveaza
        </Button>
        {changePassword ? (
          <Button onClick={() => setChangePassword(false)}>Anuleaza</Button>
        ) : (
          <Button onClick={() => setChangePassword(true)}>Schimba parola</Button>
        )}
      </div>
    </div>
  );
};

export default Personal;
