import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, useSnackbar } from '@sgde/core';
import { ChangeEvent, Reducer, useReducer, useState } from 'react';
import { ReportType } from '../../models/ReportType';
import { DocumentLibrary } from '../../models/document-library.model';
import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { AreaMeasurement } from '../../models/measurement.model';
import { ReportField } from '../../models/report-field.model';
import { GIS_CONFIG } from '../../store/settings/Local';
import useStore from '../../store/useStore';
import DynamicForm from '../Form/DynamicForm/DynamicForm';

const initialState = {};

interface Action<T> {
  reportFieldDefinitionId: number;
  files: T;
}

interface State<T> {
  [key: string]: T;
}

const createReducer =
  <T,>() =>
  (state: State<T>, action: Action<T>) => {
    return {
      ...state,
      [action.reportFieldDefinitionId]: action.files,
    };
  };

type Props = {
  feature: SGDEFeature;
  map: OpenLayersMap;
  closeDialog: () => void;
  existingMeasurement?: AreaMeasurement;
  showReportDialog?: (features: SGDEFeature[]) => void;
};

const CreateTerrain = ({ feature, closeDialog, existingMeasurement, map }: Props) => {
  const [name, setName] = useState('');
  const [error, setError] = useState(true);

  const [documents, setDocuments] = useReducer<Reducer<State<DocumentLibrary>, Action<DocumentLibrary>>>(
    createReducer<DocumentLibrary>(),
    initialState
  );

  const [additionalReportFields, setAdditionalReportFields] = useState<ReportField[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { openSnackbar } = useSnackbar();

  const addMeasurement = useStore(state => state.addMeasurement);

  const onSave = async () => {
    const report = {
      reportTypeId: 1,
      createdBy: 1,
      name: name,
    };

    let createdMeasurement = existingMeasurement;
    let measurementReportFieldValue = existingMeasurement?.parentMeasurementId;
    if (!existingMeasurement) {
      createdMeasurement = await addMeasurement(feature.getAreaMeasurement());
      measurementReportFieldValue = createdMeasurement.parentMeasurementId;
    }

    if (!createdMeasurement?.parentMeasurementId) {
      return;
    }

    const documentsReportFields: ReportField[] = [];
    for (const documentFieldId in documents) {
      const documentsFormData = new FormData();
      documents[documentFieldId]?.files.forEach(document => documentsFormData.append(document.name, document));

      const libraryResult = await fetch(`${GIS_CONFIG.BASE_API_URL}/documents/-1`, {
        method: 'POST',
        body: documentsFormData,
      });
      const library = await libraryResult.json();
      const documentsReportField = {
        reportFieldDefinitionId: parseInt(documentFieldId),
        value: String(library?.library?.id),
      } as ReportField;

      documentsReportFields.push(documentsReportField);
    }

    const reportToCreate = {
      ...report,
      reportFields: [
        ...documentsReportFields,
        ...additionalReportFields,
        {
          reportFieldDefinitionId: 36,
          value: String(true),
        },
        {
          reportFieldDefinitionId: 1,
          value: String(createdMeasurement?.area),
        },
        {
          reportFieldDefinitionId: 35,
          value: String(measurementReportFieldValue),
        },
      ],
    };
    const result = await fetch(`${GIS_CONFIG.BASE_API_URL}/reports`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reportToCreate),
    });

    if (result.status !== 200) {
      openSnackbar({ severity: 'error', message: 'Eroare la crearea constructiei' });
    }

    map.reloadMeasurementsLayer(ReportType.Imobil);
    closeDialog();
    openSnackbar({ severity: 'success', message: 'Imobil creat cu succes' });
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      setName(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <DialogTitle>Creaza raport Imobil</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={error}
              required
              onChange={onNameChange}
              variant="outlined"
              margin="normal"
              id="standard-basic"
              label="Nume"
            />
            <DynamicForm
              reportTypeId={1}
              readonly={false}
              onValidityChange={setIsValid}
              onValueChanged={setAdditionalReportFields}
              onDocumentsChange={(reportFieldDefinitionId, files) => setDocuments({ reportFieldDefinitionId, files })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={error || !isValid} onClick={onSave}>
          Salveaza
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateTerrain;
