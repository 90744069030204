import { SetState, StateCreator } from 'zustand';
import { GroundOverlay } from '../../../models/ground-overlay.model';
import { postResource } from '../../Fetch.ts';
import { GIS_CONFIG } from '../../settings/Local.ts';
import { GlobalState } from '../../useStore.ts';

export interface OrtophotoplanState {
  groundOverlays: GroundOverlay[];

  getGroundOverlays: (bounds?: number[][], skip?: number) => void;

  cleanGroundOverlays: () => void;
}

const createOrtophotoplanSlice: StateCreator<OrtophotoplanState, SetState<GlobalState>> = set => ({
  groundOverlays: [],
  getGroundOverlays: async bounds => {
    const groundOverlays = await postResource(`${GIS_CONFIG.BASE_API_URL}/ground-overlays`, bounds);
    set({ groundOverlays });
  },
  cleanGroundOverlays: () => {
    set({ groundOverlays: [] });
  },
});

export default createOrtophotoplanSlice;
