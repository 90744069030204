import SGDEFeature from '../models/feature/sgde-feature';
import { GIS_CONFIG } from '../store/settings/Local';

export const dirtyStrokeColor = '#006ab0';

export const pristineStrokeColor = '#b04600';

export const LoadingMessage = 'Se incarca...';

const getPublicInfo = async (reportId: number, publicPageName: string) => {
  if (reportId === undefined || reportId === null) {
    return Promise.reject(new Error('Report ID is empty'));
  }
  return await fetch(`${GIS_CONFIG.BASE_API_URL}/publicPage/${publicPageName}/${reportId}`);
};

const getPolygonOverlayOnClick = async (feature: SGDEFeature): Promise<string | Record<string, string>[]> => {
  const publicPageName = window.location.pathname.split('public/')[1];

  if (publicPageName && feature.reportId) {
    const publicInfoResponse = await getPublicInfo(feature.reportId, publicPageName);
    const publicInfo = await publicInfoResponse.json();
    return publicInfo;
  }

  return '';
};

export { getPolygonOverlayOnClick };
